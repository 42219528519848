/* dark mode style guide:
background: #222
text: #aaa
base: #3E188F
structural: #46346E
edge: #b4a0df
ghost: #444
border: #210D4D
hover: #393939
other: #4F1FB8, #7059A2
gentle highlight: #2b2b2b
medium highlight: #67539177
highlight with transparency: #777a
recessed: #101010
*/

:root {
  --grey0: #151515;
  --grey1: #222222;
  --grey2: #3d3d3d;
  --grey3: #515151;
  --grey4: #838383;
  --grey5: #aaaaaa;
  --grey6: #d4d4d4;
  --grey7: #eeeeee;
  --purp0: #675391;
  --purp1: #46346e;
}

body {
  font-family: Verdana, Geneva, sans-serif;
  background: var(--grey1);
  color: #aaa;
  width: 100%;
  height: 100%;
  margin: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

section {
  background-color: var(--grey0);
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
  text-align: center;
}

.title {
  display: inline;
}
.preview {
  width: 100%;
  max-height: -webkit-fill-available;
  object-fit: contain;
  background-size: cover;
  border: solid #aaa;
  border-width: 3px;
}
.bkg {
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  background-position: center;
}
.timeline {
  max-height: 40vh;
  /* display: flex; */
  display: -webkit-inline-box;
  overflow: auto;
  border: 3px solid #46346e;
  border-radius: 1em;
  padding: 1em;
  background-color: #101010;
  height: fit-content;
}
.clip {
  display: inline-flex;
  position: relative;
  /* width: 300px;
  max-height: 3em; */
  min-width: 1px;
  min-height: 40px;
  margin: 5px;
  margin-left: 0px;
  margin-right: 0px;
  background-color: #3e188f;
  color: #ccc;
  border-radius: 0.5em;
  border: 1px solid #210d4d;
  box-sizing: border-box;
  /* padding: 10px; */
  touch-action: none;
  user-select: none;
  -webkit-transform: translate(0px, 0px);
  transform: translate(0px, 0px);
  /* transition: all 1s ease-out; */
  height: 1em;
  /* text-overflow: ellipsis; */
  overflow: hidden;
}
.template {
  background-color: #520e7d;
}
.selected {
  background-color: #4f1fb8;
  border: solid #393939 1px;
}
.monitor {
  text-align: right;
  min-width: 40vw;
  max-height: 50vh;
  border: 4px solid #210d4d;
}

.project {
  border: 3px solid #210d4d;
  padding: 0.5em;
  border-radius: 1em;
  background-color: #292929;
  max-height: 45vh;
}
.project tr:nth-child(even) {
  background-color: #2b2b2b;
}
.project tr:hover {
  background-color: #67539177;
}
.project th {
  padding: 0.5em;
  background-color: #46346e;
  color: #ddd;
  position: sticky;
  top: -0.6em;
}
.renders {
  display: flow-root;
  position: relative;
  overflow-y: scroll;
}
td {
  text-align: -webkit-center;
}
tr td:first-child {
  text-align: left;
}
th {
  text-align: center;
  z-index: 1;
}
.bin {
  display: inherit;
  max-height: 40vh;
  margin-top: 0.5em;
  padding: 0.5em;
}
.head {
  display: flex;
  height: 40vh;
  background-color: black;
  border: 3px #46346e;
  border-style: solid;
  margin-bottom: 0px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 6px;
}
@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.timelinegrid {
  display: inline-grid;
  grid-template-columns: 10% 90%;
  flex-basis: 0;
}
.vcenter {
  min-width: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.center {
  margin-left: auto;
  margin-right: auto;
}
.break {
  flex-basis: 100%;
  height: 0;
}
.fit {
  width: fit-content;
}
.bar {
  display: flex;
  width: 100%;
}
.fullwidth {
  max-width: 100vw;
}
.toolbar {
  display: flex;
  background-color: #202020;
  /* border: #46346E;
  border-width: 4px;
  border-style: solid; */
  border-radius: 12px;
  margin-bottom: 0.5em;
  padding: 2px;
  margin-left: auto;
  margin-right: auto;
}
.toolcol {
  display: grid;
  grid-template-columns: 50% 50%;
}

.tools {
  justify-content: space-between;
  width: fit-content;
  height: fit-content;
}
.tools i {
  border-style: solid;
  border-color: #393939;
  border-radius: 10px;
  padding: 6px;
  margin: 2px;
  width: 1em;
  height: 1em;
  text-align: center;
  font-style: normal;
}
.tools i:hover {
  background-color: #393939;
}
.tools i:active {
  animation: blinker 0.2s 1;
}
.tools .custom {
  padding: 10px;
}
@media screen and (max-width: 700px) {
  .tools .custom {
    padding: 9px;
  }
  .toolcol {
    grid-template-columns: 100%;
  }
}
.vl {
  /* border-left: 2px #393939;
  height: 100%; */
  width: 25px;
}
select {
  color: #aaa;
  background-color: #210d4d;
  border-radius: 0.4em;
  margin: auto;
}
.sliders {
  padding: 1em;
}
/* the slider bar */
.slider {
  width: 100%;
  height: 0.4em;
  margin: auto 1em auto;
  background-color: #46346e;
  border-radius: 0.5em;
  box-sizing: border-box;
  font-size: 1em;

  -ms-touch-action: none;
  touch-action: none;
}

/* the slider handle */
.slider:before {
  content: '';
  display: block;
  position: relative;
  top: -10px;

  width: 2em;
  height: 2em;
  border: solid 0.1em #b4a0df;
  border-radius: 1em;
  background-color: inherit;

  box-sizing: border-box;
}

/* display the value */
.slider:after {
  content: attr(data-value);
  position: absolute;
  top: -1.5em;
  width: 2em;
  line-height: 1em;
  margin-left: -1em;
  text-align: center;
}

.resizable {
  background: white;
  width: 100px;
  height: 100px;
  position: absolute;
  top: 100px;
  left: 100px;
}

.resizable .resizers {
  width: 100%;
  height: 100%;
  border: 3px solid #4286f4;
  box-sizing: border-box;
}

.resizable .resizers .resizer {
  width: 10px;
  height: 10px;
  border-radius: 50%; /*magic to turn square into circle*/
  background: white;
  border: 3px solid #4286f4;
  position: absolute;
}
.sortable {
  display: inline;
  padding: 10%;
  border-radius: 20px;
  width: 200px;
  /* height: 100px; */
}
.ghost {
  opacity: 0.5;
  background: #210d4d;
}

.clip p {
  display: inline-block;
  padding-left: 15px;
  pointer-events: none;
}

.progress {
  width: 2px;
  height: 100%;
  top: 0px;
  background-color: #b4a0df;
  position: absolute;
}

::-webkit-scrollbar {
  width: 20px;
  height: 20px;
}
::-webkit-scrollbar-track {
  background-color: #000;
}
::-webkit-scrollbar-track-piece {
  background-color: #000;
}
::-webkit-scrollbar-thumb {
  height: 50px;
  background-color: #444;
  border-radius: 3px;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #666;
}
::-webkit-scrollbar-corner {
  background-color: #666;
}
::-webkit-resizer {
  background-color: #888;
}
::-webkit-scrollbar-button:single-button {
  height: 20px;
}
::-webkit-scrollbar-button:single-button:vertical:decrement {
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  background: linear-gradient(120deg, #888 40%, rgba(0, 0, 0, 0) 41%),
    linear-gradient(240deg, #888 40%, rgba(0, 0, 0, 0) 41%),
    linear-gradient(0deg, #888 30%, rgba(0, 0, 0, 0) 31%);
  background-color: #444;
}
::-webkit-scrollbar-button:single-button:vertical:increment {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  background: linear-gradient(300deg, #888 40%, rgba(0, 0, 0, 0) 41%),
    linear-gradient(60deg, #888 40%, rgba(0, 0, 0, 0) 41%),
    linear-gradient(180deg, #888 30%, rgba(0, 0, 0, 0) 31%);
  background-color: #444;
}
::-webkit-scrollbar-button:horizontal:end:increment {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  background: linear-gradient(210deg, #888 40%, rgba(0, 0, 0, 0) 41%),
    linear-gradient(330deg, #888 40%, rgba(0, 0, 0, 0) 41%),
    linear-gradient(90deg, #888 30%, rgba(0, 0, 0, 0) 31%);
  background-color: #444;
}
::-webkit-scrollbar-button:horizontal:start:decrement {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  background: linear-gradient(30deg, #888 40%, rgba(0, 0, 0, 0) 41%),
    linear-gradient(150deg, #888 40%, rgba(0, 0, 0, 0) 41%),
    linear-gradient(270deg, #888 30%, rgba(0, 0, 0, 0) 31%);
  background-color: #444;
}

form {
  padding: 1em;
  border: 3px solid #46346e;
  border-radius: 1em;
  background-color: #393939;
}
@media screen and (min-width: 769px) {
  form {
    margin: 1em;
  }
}
form label {
  text-align: end;
  display: inline-block;
  min-width: 15%;
  margin-right: 1em;
  vertical-align: middle;
}
form input[type='text'] {
  min-width: 80%;
  margin-top: 1em;
  margin-bottom: 1em;
  vertical-align: middle;
}
form textarea {
  min-width: 80%;
  height: 6em;
  margin-top: 1em;
  margin-bottom: 1em;
  vertical-align: middle;
}
form select {
  min-width: auto;
  height: 2em;
  margin-top: 1em;
  margin-bottom: 1em;
  vertical-align: middle;
}

form input {
  margin-top: 1em;
  margin-bottom: 1em;
  margin-right: 1em;
}

.button {
  font-size: 1.3em;
  padding: 0.5em;
  border-radius: 1em;
  color: #aaa;
  background-color: #46346e;
  /* opacity: .8; */
  box-shadow: -2px 2px #222;
}
.button:hover {
  /* background-color: #7059A2; */
  transition: 0.3s;
  opacity: 1;
}
.button:active {
  background-color: #4f1fb8;
  box-shadow: 0 4px #393939;
  transform: translateY(4px);
}
.subform span {
  display: inline-block;
}
.formthumb {
  padding: 0.5em;
  min-width: 5vw;
  max-width: 50vw;
  min-height: 5vh;
  max-height: 20vh;
}
.textthumb {
  display: inline-block;
  padding: 1em;
  width: 30%;
}
.subform {
  border: #46346e;
  border-width: 4px;
  border-style: solid;
  border-radius: 10px;
  margin-left: auto;
  margin-left: 15vw;
  margin-right: 1em;
  padding: 1em;
}
.form audio {
  display: inline-block;
  width: 25%;
  min-width: 150px;
  padding: 1em;
  vertical-align: middle;
}
#files {
  padding: 1em;
}

.right {
  float: right;
}
.logo {
  width: 3em;
}

/* Tooltip container */
.tooltip {
  position: relative;
  border-bottom: 2px dotted #67539177; /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #111;
  color: #ccc;
  text-align: center;
  padding: 0.4em;
  border-radius: 12px;

  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

.loader {
  border: 16px solid #444;
  border-top: 16px solid #aaa;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@function material-icons-content($codepoint) {
  @return unquote('"\\#{$codepoint}"');
}

@function normalize-class-name($class-name) {
  $class-name-first-char: str-slice($class-name, 0, 1);

  // if the first character is a number, then we need to prefix it with something (since this is invalid class name)
  @if str-index('1234567890', $class-name-first-char) {
    $class-name: '_' + $class-name;
  }

  @return $class-name;
}

@mixin material-icon($name, $pseudo: 'before') {
  $codepoint: map-get($material-icons-codepoints, $name);

  &:#{$pseudo} {
    content: material-icons-content($codepoint);
  }
}

@mixin main-class(){
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;

  /* Respect document layout direction */
  direction: inherit;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';

  @if $material-design-icons-class-icons-generate-codepoints {
    @each $name, $codepoint in $material-icons-codepoints {
      $codepoint: map-get($material-icons-codepoints, $name);

      $class-name: $material-design-icons-class-icons-prefix + $name;
      $class-name: normalize-class-name($class-name);

      &.#{$class-name} {
        &:before {
          content: material-icons-content($codepoint);
        }
      }
    }
  }
}

@mixin main-font-face() {
  @font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    font-display: block;
    src: url("#{$material-design-icons-font-file-path}.eot"); /* For IE6-8 */
    src: local('☺'),
    url("#{$material-design-icons-font-file-path}.woff2") format('woff2'),
    url("#{$material-design-icons-font-file-path}.woff") format('woff'),
    url("#{$material-design-icons-font-file-path}.ttf") format('truetype');
  }
}

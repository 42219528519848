:root {
  --grey0: #151515;
  --grey1: #222;
  --grey2: #3d3d3d;
  --grey3: #515151;
  --grey4: #838383;
  --grey5: #aaa;
  --grey6: #d4d4d4;
  --grey7: #eee;
  --purp0: #675391;
  --purp1: #46346e;
}

body {
  background: var(--grey1);
  color: #aaa;
  box-sizing: border-box;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: 0;
  font-family: Verdana, Geneva, sans-serif;
  display: flex;
  overflow: hidden;
}

section {
  background-color: var(--grey0);
  text-align: center;
  flex-direction: column;
  flex-grow: 1;
  display: flex;
  overflow-y: auto;
}

.title {
  display: inline;
}

.preview {
  object-fit: contain;
  background-size: cover;
  border: 3px solid #aaa;
  width: 100%;
  max-height: -webkit-fill-available;
}

.bkg {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
}

.timeline {
  background-color: #101010;
  border: 3px solid #46346e;
  border-radius: 1em;
  height: -moz-fit-content;
  height: fit-content;
  max-height: 40vh;
  padding: 1em;
  display: -webkit-inline-box;
  overflow: auto;
}

.clip {
  color: #ccc;
  box-sizing: border-box;
  touch-action: none;
  -webkit-user-select: none;
  user-select: none;
  background-color: #3e188f;
  border: 1px solid #210d4d;
  border-radius: .5em;
  min-width: 1px;
  height: 1em;
  min-height: 40px;
  margin: 5px 0;
  display: inline-flex;
  position: relative;
  overflow: hidden;
  transform: translate(0);
}

.template {
  background-color: #520e7d;
}

.selected {
  background-color: #4f1fb8;
  border: 1px solid #393939;
}

.monitor {
  text-align: right;
  border: 4px solid #210d4d;
  min-width: 40vw;
  max-height: 50vh;
}

.project {
  background-color: #292929;
  border: 3px solid #210d4d;
  border-radius: 1em;
  max-height: 45vh;
  padding: .5em;
}

.project tr:nth-child(2n) {
  background-color: #2b2b2b;
}

.project tr:hover {
  background-color: #67539177;
}

.project th {
  color: #ddd;
  background-color: #46346e;
  padding: .5em;
  position: sticky;
  top: -.6em;
}

.renders {
  display: flow-root;
  position: relative;
  overflow-y: scroll;
}

td {
  text-align: -webkit-center;
}

tr td:first-child {
  text-align: left;
}

th {
  text-align: center;
  z-index: 1;
}

.bin {
  display: inherit;
  max-height: 40vh;
  margin-top: .5em;
  padding: .5em;
}

.head {
  background-color: #000;
  border: 3px solid #46346e;
  border-radius: 6px;
  height: 40vh;
  margin-bottom: 0;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.timelinegrid {
  flex-basis: 0;
  grid-template-columns: 10% 90%;
  display: inline-grid;
}

.vcenter {
  flex-direction: column;
  justify-content: center;
  min-width: 0;
  display: flex;
}

.center {
  margin-left: auto;
  margin-right: auto;
}

.break {
  flex-basis: 100%;
  height: 0;
}

.fit {
  width: -moz-fit-content;
  width: fit-content;
}

.bar {
  width: 100%;
  display: flex;
}

.fullwidth {
  max-width: 100vw;
}

.toolbar {
  background-color: #202020;
  border-radius: 12px;
  margin-bottom: .5em;
  margin-left: auto;
  margin-right: auto;
  padding: 2px;
  display: flex;
}

.toolcol {
  grid-template-columns: 50% 50%;
  display: grid;
}

.tools {
  justify-content: space-between;
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.tools i {
  text-align: center;
  border-style: solid;
  border-color: #393939;
  border-radius: 10px;
  width: 1em;
  height: 1em;
  margin: 2px;
  padding: 6px;
  font-style: normal;
}

.tools i:hover {
  background-color: #393939;
}

.tools i:active {
  animation: .2s blinker;
}

.tools .custom {
  padding: 10px;
}

@media screen and (width <= 700px) {
  .tools .custom {
    padding: 9px;
  }

  .toolcol {
    grid-template-columns: 100%;
  }
}

.vl {
  width: 25px;
}

select {
  color: #aaa;
  background-color: #210d4d;
  border-radius: .4em;
  margin: auto;
}

.sliders {
  padding: 1em;
}

.slider {
  box-sizing: border-box;
  -ms-touch-action: none;
  touch-action: none;
  background-color: #46346e;
  border-radius: .5em;
  width: 100%;
  height: .4em;
  margin: auto 1em;
  font-size: 1em;
}

.slider:before {
  content: "";
  background-color: inherit;
  box-sizing: border-box;
  border: .1em solid #b4a0df;
  border-radius: 1em;
  width: 2em;
  height: 2em;
  display: block;
  position: relative;
  top: -10px;
}

.slider:after {
  content: attr(data-value);
  text-align: center;
  width: 2em;
  margin-left: -1em;
  line-height: 1em;
  position: absolute;
  top: -1.5em;
}

.resizable {
  background: #fff;
  width: 100px;
  height: 100px;
  position: absolute;
  top: 100px;
  left: 100px;
}

.resizable .resizers {
  box-sizing: border-box;
  border: 3px solid #4286f4;
  width: 100%;
  height: 100%;
}

.resizable .resizers .resizer {
  background: #fff;
  border: 3px solid #4286f4;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  position: absolute;
}

.sortable {
  border-radius: 20px;
  width: 200px;
  padding: 10%;
  display: inline;
}

.ghost {
  opacity: .5;
  background: #210d4d;
}

.clip p {
  pointer-events: none;
  padding-left: 15px;
  display: inline-block;
}

.progress {
  background-color: #b4a0df;
  width: 2px;
  height: 100%;
  position: absolute;
  top: 0;
}

::-webkit-scrollbar {
  width: 20px;
  height: 20px;
}

::-webkit-scrollbar-track {
  background-color: #000;
}

::-webkit-scrollbar-track-piece {
  background-color: #000;
}

::-webkit-scrollbar-thumb {
  background-color: #444;
  border-radius: 3px;
  height: 50px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #666;
}

::-webkit-scrollbar-corner {
  background-color: #666;
}

::-webkit-resizer {
  background-color: #888;
}

::-webkit-scrollbar-button:single-button {
  height: 20px;
}

::-webkit-scrollbar-button:single-button:vertical:decrement {
  background: linear-gradient(120deg, #888 40%, #0000 41%), linear-gradient(240deg, #888 40%, #0000 41%), #444 linear-gradient(0deg, #888 30%, #0000 31%);
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

::-webkit-scrollbar-button:single-button:vertical:increment {
  background: linear-gradient(300deg, #888 40%, #0000 41%), linear-gradient(60deg, #888 40%, #0000 41%), #444 linear-gradient(#888 30%, #0000 31%);
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

::-webkit-scrollbar-button:horizontal:end:increment {
  background: linear-gradient(210deg, #888 40%, #0000 41%), linear-gradient(330deg, #888 40%, #0000 41%), #444 linear-gradient(90deg, #888 30%, #0000 31%);
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

::-webkit-scrollbar-button:horizontal:start:decrement {
  background: linear-gradient(30deg, #888 40%, #0000 41%), linear-gradient(150deg, #888 40%, #0000 41%), #444 linear-gradient(270deg, #888 30%, #0000 31%);
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

form {
  background-color: #393939;
  border: 3px solid #46346e;
  border-radius: 1em;
  padding: 1em;
}

@media screen and (width >= 769px) {
  form {
    margin: 1em;
  }
}

form label {
  text-align: end;
  vertical-align: middle;
  min-width: 15%;
  margin-right: 1em;
  display: inline-block;
}

form input[type="text"] {
  vertical-align: middle;
  min-width: 80%;
  margin-top: 1em;
  margin-bottom: 1em;
}

form textarea {
  vertical-align: middle;
  min-width: 80%;
  height: 6em;
  margin-top: 1em;
  margin-bottom: 1em;
}

form select {
  vertical-align: middle;
  min-width: auto;
  height: 2em;
  margin-top: 1em;
  margin-bottom: 1em;
}

form input {
  margin-top: 1em;
  margin-bottom: 1em;
  margin-right: 1em;
}

.button {
  color: #aaa;
  background-color: #46346e;
  border-radius: 1em;
  padding: .5em;
  font-size: 1.3em;
  box-shadow: -2px 2px #222;
}

.button:hover {
  opacity: 1;
  transition: all .3s;
}

.button:active {
  background-color: #4f1fb8;
  transform: translateY(4px);
  box-shadow: 0 4px #393939;
}

.subform span {
  display: inline-block;
}

.formthumb {
  min-width: 5vw;
  max-width: 50vw;
  min-height: 5vh;
  max-height: 20vh;
  padding: .5em;
}

.textthumb {
  width: 30%;
  padding: 1em;
  display: inline-block;
}

.subform {
  border: 4px solid #46346e;
  border-radius: 10px;
  margin-left: 15vw;
  margin-right: 1em;
  padding: 1em;
}

.form audio {
  vertical-align: middle;
  width: 25%;
  min-width: 150px;
  padding: 1em;
  display: inline-block;
}

#files {
  padding: 1em;
}

.right {
  float: right;
}

.logo {
  width: 3em;
}

.tooltip {
  border-bottom: 2px dotted #67539177;
  position: relative;
}

.tooltip .tooltiptext {
  visibility: hidden;
  color: #ccc;
  text-align: center;
  z-index: 1;
  background-color: #111;
  border-radius: 12px;
  width: 120px;
  padding: .4em;
  position: absolute;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.loader {
  border: 16px solid #444;
  border-top-color: #aaa;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: 2s linear infinite spin;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.homeCntr {
  text-align: center;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.imgTxtCntr {
  height: 700px;
  padding-bottom: 20px;
}

@media only screen and (width <= 1200px) {
  .imgTxtCntr {
    height: 550px;
  }
}

@media only screen and (width <= 900px) {
  .imgTxtCntr {
    height: 370px;
  }
}

@media only screen and (width <= 376px) {
  .imgTxtCntr {
    height: 300px;
  }
}

.imgTxtCntr > img {
  width: 740px;
}

@media only screen and (width <= 1200px) {
  .imgTxtCntr > img {
    width: 530px;
  }
}

@media only screen and (width <= 900px) {
  .imgTxtCntr > img {
    width: 370px;
  }
}

@media only screen and (width <= 376px) {
  .imgTxtCntr > img {
    width: 300px;
  }
}

.txtBox {
  text-align: left;
  z-index: 1;
  background-color: #000;
  width: 300px;
  min-width: 300px;
  height: 300px;
  padding: 1.5em;
  font-size: 20px;
  position: relative;
}

.txtBox .firstLine {
  color: var(--grey7);
  font-size: 28px;
  font-weight: bold;
}

@media only screen and (width <= 1200px) {
  .txtBox {
    height: 250px;
    font-size: 17px;
  }

  .txtBox .firstLine {
    font-size: 28px;
  }
}

@media only screen and (width <= 900px) {
  .txtBox {
    width: 230px;
    min-width: 230px;
    height: 200px;
    font-size: 12px;
  }

  .txtBox .firstLine {
    font-size: 24px;
  }
}

@media only screen and (width <= 376px) {
  .txtBox {
    height: 150px;
    font-size: 11px;
  }

  .txtBox .firstLine {
    font-size: 20px;
  }
}

hr {
  border: none;
  border-top: 1px solid var(--grey4);
  width: 600px;
}

@media only screen and (width <= 700px) {
  hr {
    width: 300px;
  }
}

.alQuote {
  margin-top: 10px;
}

.alQuote i {
  font-size: 18px;
}

.alQuote .text {
  margin: 10px;
  font-weight: bold;
}

.honorText {
  text-align: center;
  padding: 20px;
}

.rightImg {
  height: 100%;
  position: relative;
  left: -150px;
}

.leftImg {
  height: 100%;
  position: relative;
  left: 150px;
}

.alfredText, .thenText {
  top: -500px;
  left: 400px;
}

.firstText, .finallyText {
  top: -500px;
  left: -130px;
}

@media only screen and (width <= 1200px) {
  .firstText, .finallyText {
    left: -80px;
  }
}

@media only screen and (width <= 900px) {
  .rightImg {
    left: -100px;
  }

  .leftImg {
    left: 80px;
  }

  .alfredText {
    top: -270px;
    left: 380px;
  }

  .firstText {
    top: -270px;
    left: 30px;
  }

  .thenText {
    top: -350px;
    left: 380px;
  }

  .finallyText {
    top: -350px;
    left: 30px;
  }
}

@media only screen and (width <= 665px) {
  .rightImg, .leftImg {
    left: 0;
  }

  .alfredText {
    top: -270px;
    left: 230px;
  }

  .firstText {
    top: -270px;
    left: 0;
  }

  .thenText {
    top: -350px;
    left: 230px;
  }

  .finallyText {
    top: -350px;
    left: 0;
  }
}

@media only screen and (width <= 525px) {
  .rightImg, .leftImg {
    left: 0;
  }

  .alfredText {
    top: -270px;
    left: 132px;
  }

  .firstText {
    top: -270px;
    left: 0;
  }

  .thenText {
    top: -350px;
    left: 132px;
  }

  .finallyText {
    top: -350px;
    left: 0;
  }
}

@media only screen and (width <= 376px) {
  .rightImg, .leftImg {
    top: 0;
    left: 0;
  }

  .alfredText, .firstText, .thenText, .finallyText {
    top: -280px;
    left: 0;
  }
}

.addButton {
  margin-left: auto;
  margin-right: auto;
  padding: 1em .7em .7em;
}

.addButton > a {
  cursor: pointer;
  background-color: var(--purp1);
  text-align: center;
  color: var(--grey6);
  border-radius: 1em;
  width: 18em;
  padding: .7em;
  font-size: 1.2em;
  font-weight: bold;
  text-decoration: none;
}

.addButton > a:hover {
  background-color: var(--grey6);
  color: var(--grey0);
}

.projects {
  flex-direction: column;
  width: 830px;
  margin-left: auto;
  margin-right: auto;
  padding: 1em 1em 2em;
  display: flex;
  overflow-y: hidden;
}

.projTableHead {
  background-color: var(--purp1);
}

.projTableHead > th {
  color: var(--grey7);
}

.projTableHead:first-of-type {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.projTableHead > th:first-child {
  width: 60%;
}

.projTableContainer {
  border: .7em var(--grey1) solid;
  background-color: var(--grey1);
  border-bottom-right-radius: 1em;
  border-bottom-left-radius: 1em;
  max-height: 800px;
  overflow: auto;
}

.projTable {
  padding: 0;
}

.projTable > tr > td:first-of-type {
  width: 60%;
}

.projTable > tr:first-child > td:first-of-type {
  border-top-left-radius: 0;
}

.projTable > tr:first-child > td:last-of-type {
  border-top-right-radius: 0;
}

.projTable > tr:last-child > td:first-of-type {
  border-bottom-left-radius: 0;
}

.projTable > tr:last-child > td:last-of-type {
  border-bottom-right-radius: 0;
}

table {
  background-color: var(--grey1);
  border-radius: 1em;
  width: 100%;
  padding: .5em;
}

th {
  background-color: var(--purp1);
  color: var(--grey7);
  padding: .5em;
  position: sticky;
  top: 0;
}

th:first-child {
  border-top-left-radius: .5em;
}

th:last-child {
  border-top-right-radius: .5em;
}

tr:nth-child(odd) {
  background-color: var(--grey2);
  color: var(--grey6);
}

tr:nth-child(2n) {
  background-color: var(--grey3);
  color: var(--grey6);
}

tr:hover {
  background-color: var(--purp1);
}

td {
  padding: .5em;
}

td > .material-icons {
  cursor: pointer;
  color: var(--grey5);
}

td > .material-icons:hover {
  cursor: pointer;
  color: var(--grey7);
}

tr:first-child > td:first-of-type {
  border-top-left-radius: .5em;
}

tr:first-child > td:last-of-type {
  border-top-right-radius: .5em;
}

tr:last-child > td:first-of-type {
  border-bottom-left-radius: .5em;
}

tr:last-child > td:last-of-type {
  border-bottom-right-radius: .5em;
}

.docLink {
  text-align: center;
  color: var(--grey5);
  width: 12em;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
  font-size: 20px;
  font-weight: bold;
  text-decoration: none;
}

.docLink:hover {
  cursor: pointer;
  color: var(--grey7);
}

h1 {
  margin-bottom: 30px;
}

.alertify .ajs-dimmer {
  z-index: 1981;
  opacity: .5;
  background-color: #252525;
  margin: 0;
  padding: 0;
  position: fixed;
  inset: 0;
}

.alertify .ajs-modal {
  z-index: 1981;
  padding: 0;
  position: fixed;
  inset: 0;
  overflow-y: auto;
}

.alertify .ajs-dialog {
  background-color: #fff;
  outline: 0;
  max-width: 500px;
  min-height: 110px;
  margin: 5% auto;
  padding: 24px 24px 0;
  position: relative;
}

.alertify .ajs-dialog.ajs-capture:before {
  content: "";
  z-index: 1;
  display: block;
  position: absolute;
  inset: 0;
}

.alertify .ajs-reset {
  opacity: 0 !important;
  width: 0 !important;
  height: 0 !important;
  display: inline !important;
  position: absolute !important;
}

.alertify .ajs-commands {
  z-index: 2;
  margin: -14px 24px 0 0;
  position: absolute;
  right: 4px;
}

.alertify .ajs-commands button {
  cursor: pointer;
  background-color: #0000;
  background-position: center;
  background-repeat: no-repeat;
  border: 0;
  width: 10px;
  height: 10px;
  margin-left: 10px;
  padding: 10px;
  display: none;
}

.alertify .ajs-commands button.ajs-close {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEgAACxIB0t1+/AAAABZ0RVh0Q3JlYXRpb24gVGltZQAwNy8xMy8xNOrZqugAAAAcdEVYdFNvZnR3YXJlAEFkb2JlIEZpcmV3b3JrcyBDUzbovLKMAAAAh0lEQVQYlY2QsQ0EIQwEB9cBAR1CJUaI/gigDnwR6NBL/7/xWLNrZ2b8EwGotVpr7eOitWa1VjugiNB7R1UPrKrWe0dEAHBbXUqxMQbeewDmnHjvyTm7C3zDwAUd9c63YQdUVdu6EAJzzquz7HXvTiklt+H9DQFYaxFjvDqllFyMkbXWvfpXHjJrWFgdBq/hAAAAAElFTkSuQmCC");
}

.alertify .ajs-commands button.ajs-maximize {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEgAACxIB0t1+/AAAABZ0RVh0Q3JlYXRpb24gVGltZQAwNy8xMy8xNOrZqugAAAAcdEVYdFNvZnR3YXJlAEFkb2JlIEZpcmV3b3JrcyBDUzbovLKMAAAAOUlEQVQYlWP8//8/AzGAhYGBgaG4uBiv6t7eXkYmooxjYGAgWiELsvHYFMCcRX2rSXcjoSBiJDbAAeD+EGu+8BZcAAAAAElFTkSuQmCC");
}

.alertify .ajs-header {
  background-color: #fff;
  margin: -24px -24px 0;
  padding: 16px 24px;
}

.alertify .ajs-body {
  min-height: 56px;
}

.alertify .ajs-body .ajs-content {
  padding: 16px 24px 16px 16px;
}

.alertify .ajs-footer {
  background-color: #fff;
  min-height: 43px;
  margin-left: -24px;
  margin-right: -24px;
  padding: 4px;
}

.alertify .ajs-footer .ajs-buttons.ajs-primary {
  text-align: right;
}

.alertify .ajs-footer .ajs-buttons.ajs-primary .ajs-button {
  margin: 4px;
}

.alertify .ajs-footer .ajs-buttons.ajs-auxiliary {
  float: left;
  clear: none;
  text-align: left;
}

.alertify .ajs-footer .ajs-buttons.ajs-auxiliary .ajs-button {
  margin: 4px;
}

.alertify .ajs-footer .ajs-buttons .ajs-button {
  min-width: 88px;
  min-height: 35px;
}

.alertify .ajs-handle {
  z-index: 1;
  cursor: se-resize;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEgAACxIB0t1+/AAAABZ0RVh0Q3JlYXRpb24gVGltZQAwNy8xMS8xNEDQYmMAAAAcdEVYdFNvZnR3YXJlAEFkb2JlIEZpcmV3b3JrcyBDUzbovLKMAAAAQ0lEQVQYlaXNMQoAIAxD0dT7H657l0KX3iJuUlBUNOsPPCGJm7VDp6ryeMxMuDsAQH7owW3pyn3RS26iKxERMLN3ugOaAkaL3sWVigAAAABJRU5ErkJggg==");
  width: 10px;
  height: 10px;
  display: none;
  position: absolute;
  bottom: 0;
  right: 0;
  transform: scaleX(1);
}

.alertify.ajs-no-overflow .ajs-body .ajs-content {
  overflow: hidden !important;
}

.alertify.ajs-no-padding.ajs-maximized .ajs-body .ajs-content {
  padding: 0;
  left: 0;
  right: 0;
}

.alertify.ajs-no-padding:not(.ajs-maximized) .ajs-body {
  margin-left: -24px;
  margin-right: -24px;
}

.alertify.ajs-no-padding:not(.ajs-maximized) .ajs-body .ajs-content {
  padding: 0;
}

.alertify.ajs-no-padding.ajs-resizable .ajs-body .ajs-content {
  left: 0;
  right: 0;
}

.alertify.ajs-maximizable .ajs-commands button.ajs-maximize, .alertify.ajs-maximizable .ajs-commands button.ajs-restore, .alertify.ajs-closable .ajs-commands button.ajs-close {
  display: inline-block;
}

.alertify.ajs-maximized .ajs-dialog {
  width: 100% !important;
  max-width: none !important;
  height: 100% !important;
  margin: 0 auto !important;
  top: 0 !important;
  left: 0 !important;
}

.alertify.ajs-maximized.ajs-modeless .ajs-modal {
  min-height: 100% !important;
  max-height: none !important;
  margin: 0 !important;
  position: fixed !important;
}

.alertify.ajs-maximized .ajs-commands button.ajs-maximize {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEgAACxIB0t1+/AAAABZ0RVh0Q3JlYXRpb24gVGltZQAwNy8xMy8xNOrZqugAAAAcdEVYdFNvZnR3YXJlAEFkb2JlIEZpcmV3b3JrcyBDUzbovLKMAAAASklEQVQYlZWQ0QkAMQhDtXRincOZX78KVtrDCwgqJNEoIB3MPLj7lRUROlpyVXGzby6zWuY+kz6tj5sBMTMAyVV3/595RbOh3cAXsww1raeiOcoAAAAASUVORK5CYII=");
}

.alertify.ajs-maximized .ajs-dialog, .alertify.ajs-resizable .ajs-dialog {
  padding: 0;
}

.alertify.ajs-maximized .ajs-commands, .alertify.ajs-resizable .ajs-commands {
  margin: 14px 24px 0 0;
}

.alertify.ajs-maximized .ajs-header, .alertify.ajs-resizable .ajs-header {
  margin: 0;
  padding: 16px 24px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.alertify.ajs-maximized .ajs-body, .alertify.ajs-resizable .ajs-body {
  min-height: 224px;
  display: inline-block;
}

.alertify.ajs-maximized .ajs-body .ajs-content, .alertify.ajs-resizable .ajs-body .ajs-content {
  position: absolute;
  inset: 50px 24px;
  overflow: auto;
}

.alertify.ajs-maximized .ajs-footer, .alertify.ajs-resizable .ajs-footer {
  margin: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.alertify.ajs-resizable:not(.ajs-maximized) .ajs-dialog {
  min-width: 548px;
}

.alertify.ajs-resizable:not(.ajs-maximized) .ajs-handle {
  display: block;
}

.alertify.ajs-movable:not(.ajs-maximized) .ajs-header {
  cursor: move;
}

.alertify.ajs-modeless .ajs-dimmer, .alertify.ajs-modeless .ajs-reset {
  display: none;
}

.alertify.ajs-modeless .ajs-modal {
  max-width: none;
  max-height: 0;
  overflow: visible;
}

.alertify.ajs-modeless.ajs-pinnable .ajs-commands button.ajs-pin {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEgAACxIB0t1+/AAAABZ0RVh0Q3JlYXRpb24gVGltZQAwNy8xMy8xNOrZqugAAAAcdEVYdFNvZnR3YXJlAEFkb2JlIEZpcmV3b3JrcyBDUzbovLKMAAAAQklEQVQYlcWPMQ4AIAwCqU9u38GbcbHRWN1MvKQDhQFMEpKImGJA0gCgnYw0V0rwxseg5erT4oSkQVI5d9f+e9+xA0NbLpWfitPXAAAAAElFTkSuQmCC");
  display: inline-block;
}

.alertify.ajs-modeless.ajs-unpinned .ajs-modal {
  position: absolute;
}

.alertify.ajs-modeless.ajs-unpinned .ajs-commands button.ajs-pin {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEgAACxIB0t1+/AAAABZ0RVh0Q3JlYXRpb24gVGltZQAwNy8xMy8xNOrZqugAAAAcdEVYdFNvZnR3YXJlAEFkb2JlIEZpcmV3b3JrcyBDUzbovLKMAAAAO0lEQVQYlWP8//8/AzGAiShV6AqLi4txGs+CLoBLMYbC3t5eRmyaWfBZhwwYkX2NTxPRvibKjRhW4wMAhxkYGbLu3pEAAAAASUVORK5CYII=");
}

.alertify.ajs-modeless:not(.ajs-unpinned) .ajs-body {
  max-height: 500px;
  overflow: auto;
}

.alertify.ajs-basic .ajs-header {
  opacity: 0;
}

.alertify.ajs-basic .ajs-footer {
  visibility: hidden;
}

.alertify.ajs-frameless .ajs-header {
  opacity: 0;
  z-index: 1;
  min-height: 60px;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.alertify.ajs-frameless .ajs-footer {
  display: none;
}

.alertify.ajs-frameless .ajs-body .ajs-content {
  position: absolute;
  inset: 0;
}

.alertify.ajs-frameless:not(.ajs-resizable) .ajs-dialog {
  padding-top: 0;
}

.alertify.ajs-frameless:not(.ajs-resizable) .ajs-dialog .ajs-commands {
  margin-top: 0;
}

.ajs-no-overflow {
  outline: 0;
  overflow: hidden !important;
}

.ajs-no-overflow.ajs-fixed {
  position: fixed;
  inset: 0;
  overflow-y: scroll !important;
}

.ajs-no-selection, .ajs-no-selection * {
  -webkit-user-select: none;
  user-select: none;
}

@media screen and (width <= 568px) {
  .alertify .ajs-dialog {
    min-width: 150px;
  }

  .alertify:not(.ajs-maximized) .ajs-modal {
    padding: 0 5%;
  }

  .alertify:not(.ajs-maximized).ajs-resizable .ajs-dialog {
    min-width: initial;
    min-width: auto;
  }
}

@-moz-document url-prefix() {
  .alertify button:focus {
    outline: 1px dotted #3593d2;
  }
}

.alertify .ajs-dimmer, .alertify .ajs-modal {
  transition-property: opacity, visibility;
  transition-duration: .25s;
  transition-timing-function: linear;
  transform: translate3d(0, 0, 0);
}

.alertify.ajs-hidden .ajs-dimmer, .alertify.ajs-hidden .ajs-modal {
  visibility: hidden;
  opacity: 0;
}

.alertify.ajs-in:not(.ajs-hidden) .ajs-dialog {
  animation-duration: .5s;
}

.alertify.ajs-out.ajs-hidden .ajs-dialog {
  animation-duration: .25s;
}

.alertify .ajs-dialog.ajs-shake {
  animation-name: ajs-shake;
  animation-duration: .1s;
  animation-fill-mode: both;
}

@keyframes ajs-shake {
  0%, 100% {
    transform: translate3d(0, 0, 0);
  }

  10%, 30%, 50%, 70%, 90% {
    transform: translate3d(-10px, 0, 0);
  }

  20%, 40%, 60%, 80% {
    transform: translate3d(10px, 0, 0);
  }
}

.alertify.ajs-slide.ajs-in:not(.ajs-hidden) .ajs-dialog {
  animation-name: ajs-slideIn;
  animation-timing-function: cubic-bezier(.175, .885, .32, 1.275);
}

.alertify.ajs-slide.ajs-out.ajs-hidden .ajs-dialog {
  animation-name: ajs-slideOut;
  animation-timing-function: cubic-bezier(.6, -.28, .735, .045);
}

.alertify.ajs-zoom.ajs-in:not(.ajs-hidden) .ajs-dialog {
  animation-name: ajs-zoomIn;
}

.alertify.ajs-zoom.ajs-out.ajs-hidden .ajs-dialog {
  animation-name: ajs-zoomOut;
}

.alertify.ajs-fade.ajs-in:not(.ajs-hidden) .ajs-dialog {
  animation-name: ajs-fadeIn;
}

.alertify.ajs-fade.ajs-out.ajs-hidden .ajs-dialog {
  animation-name: ajs-fadeOut;
}

.alertify.ajs-pulse.ajs-in:not(.ajs-hidden) .ajs-dialog {
  animation-name: ajs-pulseIn;
}

.alertify.ajs-pulse.ajs-out.ajs-hidden .ajs-dialog {
  animation-name: ajs-pulseOut;
}

.alertify.ajs-flipx.ajs-in:not(.ajs-hidden) .ajs-dialog {
  animation-name: ajs-flipInX;
}

.alertify.ajs-flipx.ajs-out.ajs-hidden .ajs-dialog {
  animation-name: ajs-flipOutX;
}

.alertify.ajs-flipy.ajs-in:not(.ajs-hidden) .ajs-dialog {
  animation-name: ajs-flipInY;
}

.alertify.ajs-flipy.ajs-out.ajs-hidden .ajs-dialog {
  animation-name: ajs-flipOutY;
}

@keyframes ajs-pulseIn {
  0%, 100%, 20%, 40%, 60%, 80% {
    transition-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    transform: scale3d(.9, .9, .9);
  }

  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    transform: scale3d(.97, .97, .97);
  }

  100% {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

@keyframes ajs-pulseOut {
  20% {
    transform: scale3d(.9, .9, .9);
  }

  50%, 55% {
    opacity: 1;
    transform: scale3d(1.1, 1.1, 1.1);
  }

  100% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }
}

@keyframes ajs-zoomIn {
  0% {
    opacity: 0;
    transform: scale3d(.25, .25, .25);
  }

  100% {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

@keyframes ajs-zoomOut {
  0% {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }

  100% {
    opacity: 0;
    transform: scale3d(.25, .25, .25);
  }
}

@keyframes ajs-fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes ajs-fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes ajs-flipInX {
  0% {
    opacity: 0;
    transition-timing-function: ease-in;
    transform: perspective(400px)rotate3d(1, 0, 0, 90deg);
  }

  40% {
    transition-timing-function: ease-in;
    transform: perspective(400px)rotate3d(1, 0, 0, -20deg);
  }

  60% {
    opacity: 1;
    transform: perspective(400px)rotate3d(1, 0, 0, 10deg);
  }

  80% {
    transform: perspective(400px)rotate3d(1, 0, 0, -5deg);
  }

  100% {
    transform: perspective(400px);
  }
}

@keyframes ajs-flipOutX {
  0% {
    transform: perspective(400px);
  }

  30% {
    opacity: 1;
    transform: perspective(400px)rotate3d(1, 0, 0, -20deg);
  }

  100% {
    opacity: 0;
    transform: perspective(400px)rotate3d(1, 0, 0, 90deg);
  }
}

@keyframes ajs-flipInY {
  0% {
    opacity: 0;
    transition-timing-function: ease-in;
    transform: perspective(400px)rotate3d(0, 1, 0, 90deg);
  }

  40% {
    transition-timing-function: ease-in;
    transform: perspective(400px)rotate3d(0, 1, 0, -20deg);
  }

  60% {
    opacity: 1;
    transform: perspective(400px)rotate3d(0, 1, 0, 10deg);
  }

  80% {
    transform: perspective(400px)rotate3d(0, 1, 0, -5deg);
  }

  100% {
    transform: perspective(400px);
  }
}

@keyframes ajs-flipOutY {
  0% {
    transform: perspective(400px);
  }

  30% {
    opacity: 1;
    transform: perspective(400px)rotate3d(0, 1, 0, -15deg);
  }

  100% {
    opacity: 0;
    transform: perspective(400px)rotate3d(0, 1, 0, 90deg);
  }
}

@keyframes ajs-slideIn {
  0% {
    margin-top: -100%;
  }

  100% {
    margin-top: 5%;
  }
}

@keyframes ajs-slideOut {
  0% {
    margin-top: 5%;
  }

  100% {
    margin-top: -100%;
  }
}

.alertify-notifier {
  z-index: 1982;
  width: 0;
  position: fixed;
  overflow: visible;
  transform: translate3d(0, 0, 0);
}

.alertify-notifier .ajs-message {
  opacity: 0;
  width: 260px;
  max-height: 0;
  margin: 0;
  padding: 0;
  transition-duration: .25s;
  transition-timing-function: linear;
  position: relative;
  transform: translate3d(0, 0, 0);
}

.alertify-notifier .ajs-message.ajs-visible {
  opacity: 1;
  max-height: 100%;
  margin-top: 10px;
  padding: 15px;
  transition-duration: .5s;
  transition-timing-function: cubic-bezier(.175, .885, .32, 1.275);
}

.alertify-notifier .ajs-message.ajs-success {
  background: #5bbd72f2;
}

.alertify-notifier .ajs-message.ajs-error {
  background: #d95c5cf2;
}

.alertify-notifier .ajs-message.ajs-warning {
  background: #fcf8d7f2;
}

.alertify-notifier .ajs-message .ajs-close {
  cursor: pointer;
  background-color: #00000080;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAABGdBTUEAALGPC/xhBQAAAFBJREFUGBl1j0EKADEIA+ve/P9f9bh1hEihNBfjVCO1v7RKVqJK4h8gM5cAPR42AkQEpSXPwMTyoi13n5N9YqJehm3Fnr7nL1D0ZEbD5OubGyC7a9gx+9eNAAAAAElFTkSuQmCC");
  background-position: center;
  background-repeat: no-repeat;
  border-top-right-radius: 2px;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 0;
  right: 0;
}

.alertify-notifier.ajs-top {
  top: 10px;
}

.alertify-notifier.ajs-bottom {
  bottom: 10px;
}

.alertify-notifier.ajs-right {
  right: 10px;
}

.alertify-notifier.ajs-right .ajs-message {
  right: -320px;
}

.alertify-notifier.ajs-right .ajs-message.ajs-visible {
  right: 290px;
}

.alertify-notifier.ajs-left {
  left: 10px;
}

.alertify-notifier.ajs-left .ajs-message {
  left: -300px;
}

.alertify-notifier.ajs-left .ajs-message.ajs-visible {
  left: 0;
}

.alertify-notifier.ajs-center {
  left: 50%;
}

.alertify-notifier.ajs-center .ajs-message {
  transform: translateX(-50%);
}

.alertify-notifier.ajs-center .ajs-message.ajs-visible {
  transition-timing-function: cubic-bezier(.57, .43, .1, .65);
  left: 50%;
}

.alertify-notifier.ajs-center.ajs-top .ajs-message {
  top: -300px;
}

.alertify-notifier.ajs-center.ajs-top .ajs-message.ajs-visible {
  top: 0;
}

.alertify-notifier.ajs-center.ajs-bottom .ajs-message {
  bottom: -300px;
}

.alertify-notifier.ajs-center.ajs-bottom .ajs-message.ajs-visible {
  bottom: 0;
}

.ajs-no-transition.alertify .ajs-dialog, .ajs-no-transition.alertify .ajs-dimmer, .ajs-no-transition.alertify .ajs-modal, .ajs-no-transition.alertify-notifier .ajs-message {
  transition: none !important;
  animation: none !important;
}

@media (prefers-reduced-motion: reduce) {
  .alertify .ajs-dialog, .alertify .ajs-dimmer, .alertify .ajs-modal, .alertify-notifier .ajs-message {
    transition: none !important;
    animation: none !important;
  }
}

.alertify .ajs-dimmer {
  opacity: 1;
  background-color: #000000d9;
}

.alertify .ajs-dialog {
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: #f4f4f4;
  border: 1px solid #ddd;
  border-radius: 5px;
  max-width: 50%;
  min-height: 137px;
}

.alertify .ajs-header {
  color: #555;
  background-color: #fff;
  border-bottom: none;
  border-radius: 5px 5px 0 0;
  padding: 1.5rem 2rem;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 1.6em;
  font-weight: 700;
}

.alertify .ajs-body {
  color: #555;
  font-family: Roboto, sans-serif;
}

.alertify .ajs-body .ajs-content .ajs-input {
  color: #000000b3;
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #00000026;
  border-radius: .3125em;
  outline: 0;
  width: 100%;
  margin: 0;
  padding: .65em 1em;
  font-size: 1em;
  transition: background-color .3s ease-out, box-shadow .2s, border-color .2s, -webkit-box-shadow .2s;
}

.alertify .ajs-body .ajs-content .ajs-input:active {
  background-color: #fafafa;
  border-color: #0000004d;
}

.alertify .ajs-body .ajs-content .ajs-input:focus {
  color: #000000d9;
  border-color: #0003;
}

.alertify.ajs-resizable .ajs-content, .alertify.ajs-maximized:not(.ajs-resizable) .ajs-content {
  top: 64px;
  bottom: 74px;
}

.alertify .ajs-footer {
  background-color: #fff;
  border-top: none;
  border-radius: 0 0 5px 5px;
  padding: 1rem 2rem;
}

.alertify-notifier .ajs-message {
  color: #000;
  text-align: center;
  background: #fffffff2;
  border: 1px solid #ddd;
  border-radius: 2px;
}

.alertify-notifier .ajs-message.ajs-success {
  color: #fff;
  text-shadow: -1px -1px #00000080;
  background: #5bbd72f2;
}

.alertify-notifier .ajs-message.ajs-error {
  color: #fff;
  text-shadow: -1px -1px #00000080;
  background: #d95c5cf2;
}

.alertify-notifier .ajs-message.ajs-warning {
  background: #fcf8d7f2;
  border-color: #999;
}

@font-face {
  font-family: Material Icons;
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("MaterialIcons-Regular.e9e55c63.eot");
  src: local(☺), url("MaterialIcons-Regular.b99eb5ce.woff2") format("woff2"), url("MaterialIcons-Regular.12b3b105.woff") format("woff"), url("MaterialIcons-Regular.333251c4.ttf") format("truetype");
}

.material-icons {
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: inherit;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: "liga";
  font-family: Material Icons;
  font-size: 24px;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  display: inline-block;
}

.material-icons._10k:before {
  content: "";
}

.material-icons._10mp:before {
  content: "";
}

.material-icons._11mp:before {
  content: "";
}

.material-icons._123:before {
  content: "";
}

.material-icons._12mp:before {
  content: "";
}

.material-icons._13mp:before {
  content: "";
}

.material-icons._14mp:before {
  content: "";
}

.material-icons._15mp:before {
  content: "";
}

.material-icons._16mp:before {
  content: "";
}

.material-icons._17mp:before {
  content: "";
}

.material-icons._18_up_rating:before {
  content: "";
}

.material-icons._18mp:before {
  content: "";
}

.material-icons._19mp:before {
  content: "";
}

.material-icons._1k:before {
  content: "";
}

.material-icons._1k_plus:before {
  content: "";
}

.material-icons._1x_mobiledata:before {
  content: "";
}

.material-icons._20mp:before {
  content: "";
}

.material-icons._21mp:before {
  content: "";
}

.material-icons._22mp:before {
  content: "";
}

.material-icons._23mp:before {
  content: "";
}

.material-icons._24mp:before {
  content: "";
}

.material-icons._2k:before {
  content: "";
}

.material-icons._2k_plus:before {
  content: "";
}

.material-icons._2mp:before {
  content: "";
}

.material-icons._30fps:before {
  content: "";
}

.material-icons._30fps_select:before {
  content: "";
}

.material-icons._360:before {
  content: "";
}

.material-icons._3d_rotation:before {
  content: "";
}

.material-icons._3g_mobiledata:before {
  content: "";
}

.material-icons._3k:before {
  content: "";
}

.material-icons._3k_plus:before {
  content: "";
}

.material-icons._3mp:before {
  content: "";
}

.material-icons._3p:before {
  content: "";
}

.material-icons._4g_mobiledata:before {
  content: "";
}

.material-icons._4g_plus_mobiledata:before {
  content: "";
}

.material-icons._4k:before {
  content: "";
}

.material-icons._4k_plus:before {
  content: "";
}

.material-icons._4mp:before {
  content: "";
}

.material-icons._5g:before {
  content: "";
}

.material-icons._5k:before {
  content: "";
}

.material-icons._5k_plus:before {
  content: "";
}

.material-icons._5mp:before {
  content: "";
}

.material-icons._60fps:before {
  content: "";
}

.material-icons._60fps_select:before {
  content: "";
}

.material-icons._6_ft_apart:before {
  content: "";
}

.material-icons._6k:before {
  content: "";
}

.material-icons._6k_plus:before {
  content: "";
}

.material-icons._6mp:before {
  content: "";
}

.material-icons._7k:before {
  content: "";
}

.material-icons._7k_plus:before {
  content: "";
}

.material-icons._7mp:before {
  content: "";
}

.material-icons._8k:before {
  content: "";
}

.material-icons._8k_plus:before {
  content: "";
}

.material-icons._8mp:before {
  content: "";
}

.material-icons._9k:before {
  content: "";
}

.material-icons._9k_plus:before {
  content: "";
}

.material-icons._9mp:before {
  content: "";
}

.material-icons.abc:before {
  content: "";
}

.material-icons.ac_unit:before {
  content: "";
}

.material-icons.access_alarm:before {
  content: "";
}

.material-icons.access_alarms:before {
  content: "";
}

.material-icons.access_time:before {
  content: "";
}

.material-icons.access_time_filled:before {
  content: "";
}

.material-icons.accessibility:before {
  content: "";
}

.material-icons.accessibility_new:before {
  content: "";
}

.material-icons.accessible:before {
  content: "";
}

.material-icons.accessible_forward:before {
  content: "";
}

.material-icons.account_balance:before {
  content: "";
}

.material-icons.account_balance_wallet:before {
  content: "";
}

.material-icons.account_box:before {
  content: "";
}

.material-icons.account_circle:before {
  content: "";
}

.material-icons.account_tree:before {
  content: "";
}

.material-icons.ad_units:before {
  content: "";
}

.material-icons.adb:before {
  content: "";
}

.material-icons.add:before {
  content: "";
}

.material-icons.add_a_photo:before {
  content: "";
}

.material-icons.add_alarm:before {
  content: "";
}

.material-icons.add_alert:before {
  content: "";
}

.material-icons.add_box:before {
  content: "";
}

.material-icons.add_business:before {
  content: "";
}

.material-icons.add_call:before {
  content: "";
}

.material-icons.add_card:before {
  content: "";
}

.material-icons.add_chart:before {
  content: "";
}

.material-icons.add_circle:before {
  content: "";
}

.material-icons.add_circle_outline:before {
  content: "";
}

.material-icons.add_comment:before {
  content: "";
}

.material-icons.add_home:before {
  content: "";
}

.material-icons.add_home_work:before {
  content: "";
}

.material-icons.add_ic_call:before {
  content: "";
}

.material-icons.add_link:before {
  content: "";
}

.material-icons.add_location:before {
  content: "";
}

.material-icons.add_location_alt:before {
  content: "";
}

.material-icons.add_moderator:before {
  content: "";
}

.material-icons.add_photo_alternate:before {
  content: "";
}

.material-icons.add_reaction:before {
  content: "";
}

.material-icons.add_road:before {
  content: "";
}

.material-icons.add_shopping_cart:before {
  content: "";
}

.material-icons.add_task:before {
  content: "";
}

.material-icons.add_to_drive:before {
  content: "";
}

.material-icons.add_to_home_screen:before {
  content: "";
}

.material-icons.add_to_photos:before {
  content: "";
}

.material-icons.add_to_queue:before {
  content: "";
}

.material-icons.addchart:before {
  content: "";
}

.material-icons.adf_scanner:before {
  content: "";
}

.material-icons.adjust:before {
  content: "";
}

.material-icons.admin_panel_settings:before {
  content: "";
}

.material-icons.adobe:before {
  content: "";
}

.material-icons.ads_click:before {
  content: "";
}

.material-icons.agriculture:before {
  content: "";
}

.material-icons.air:before {
  content: "";
}

.material-icons.airline_seat_flat:before {
  content: "";
}

.material-icons.airline_seat_flat_angled:before {
  content: "";
}

.material-icons.airline_seat_individual_suite:before {
  content: "";
}

.material-icons.airline_seat_legroom_extra:before {
  content: "";
}

.material-icons.airline_seat_legroom_normal:before {
  content: "";
}

.material-icons.airline_seat_legroom_reduced:before {
  content: "";
}

.material-icons.airline_seat_recline_extra:before {
  content: "";
}

.material-icons.airline_seat_recline_normal:before {
  content: "";
}

.material-icons.airline_stops:before {
  content: "";
}

.material-icons.airlines:before {
  content: "";
}

.material-icons.airplane_ticket:before {
  content: "";
}

.material-icons.airplanemode_active:before {
  content: "";
}

.material-icons.airplanemode_inactive:before, .material-icons.airplanemode_off:before {
  content: "";
}

.material-icons.airplanemode_on:before {
  content: "";
}

.material-icons.airplay:before {
  content: "";
}

.material-icons.airport_shuttle:before {
  content: "";
}

.material-icons.alarm:before {
  content: "";
}

.material-icons.alarm_add:before {
  content: "";
}

.material-icons.alarm_off:before {
  content: "";
}

.material-icons.alarm_on:before {
  content: "";
}

.material-icons.album:before {
  content: "";
}

.material-icons.align_horizontal_center:before {
  content: "";
}

.material-icons.align_horizontal_left:before {
  content: "";
}

.material-icons.align_horizontal_right:before {
  content: "";
}

.material-icons.align_vertical_bottom:before {
  content: "";
}

.material-icons.align_vertical_center:before {
  content: "";
}

.material-icons.align_vertical_top:before {
  content: "";
}

.material-icons.all_inbox:before {
  content: "";
}

.material-icons.all_inclusive:before {
  content: "";
}

.material-icons.all_out:before {
  content: "";
}

.material-icons.alt_route:before {
  content: "";
}

.material-icons.alternate_email:before {
  content: "";
}

.material-icons.amp_stories:before {
  content: "";
}

.material-icons.analytics:before {
  content: "";
}

.material-icons.anchor:before {
  content: "";
}

.material-icons.android:before {
  content: "";
}

.material-icons.animation:before {
  content: "";
}

.material-icons.announcement:before {
  content: "";
}

.material-icons.aod:before {
  content: "";
}

.material-icons.apartment:before {
  content: "";
}

.material-icons.api:before {
  content: "";
}

.material-icons.app_blocking:before {
  content: "";
}

.material-icons.app_registration:before {
  content: "";
}

.material-icons.app_settings_alt:before {
  content: "";
}

.material-icons.app_shortcut:before {
  content: "";
}

.material-icons.apple:before {
  content: "";
}

.material-icons.approval:before {
  content: "";
}

.material-icons.apps:before {
  content: "";
}

.material-icons.apps_outage:before {
  content: "";
}

.material-icons.architecture:before {
  content: "";
}

.material-icons.archive:before {
  content: "";
}

.material-icons.area_chart:before {
  content: "";
}

.material-icons.arrow_back:before {
  content: "";
}

.material-icons.arrow_back_ios:before {
  content: "";
}

.material-icons.arrow_back_ios_new:before {
  content: "";
}

.material-icons.arrow_circle_down:before {
  content: "";
}

.material-icons.arrow_circle_left:before {
  content: "";
}

.material-icons.arrow_circle_right:before {
  content: "";
}

.material-icons.arrow_circle_up:before {
  content: "";
}

.material-icons.arrow_downward:before {
  content: "";
}

.material-icons.arrow_drop_down:before {
  content: "";
}

.material-icons.arrow_drop_down_circle:before {
  content: "";
}

.material-icons.arrow_drop_up:before {
  content: "";
}

.material-icons.arrow_forward:before {
  content: "";
}

.material-icons.arrow_forward_ios:before {
  content: "";
}

.material-icons.arrow_left:before {
  content: "";
}

.material-icons.arrow_right:before {
  content: "";
}

.material-icons.arrow_right_alt:before {
  content: "";
}

.material-icons.arrow_upward:before {
  content: "";
}

.material-icons.art_track:before {
  content: "";
}

.material-icons.article:before {
  content: "";
}

.material-icons.aspect_ratio:before {
  content: "";
}

.material-icons.assessment:before {
  content: "";
}

.material-icons.assignment:before {
  content: "";
}

.material-icons.assignment_ind:before {
  content: "";
}

.material-icons.assignment_late:before {
  content: "";
}

.material-icons.assignment_return:before {
  content: "";
}

.material-icons.assignment_returned:before {
  content: "";
}

.material-icons.assignment_turned_in:before {
  content: "";
}

.material-icons.assistant:before {
  content: "";
}

.material-icons.assistant_direction:before {
  content: "";
}

.material-icons.assistant_navigation:before {
  content: "";
}

.material-icons.assistant_photo:before {
  content: "";
}

.material-icons.assured_workload:before {
  content: "";
}

.material-icons.atm:before {
  content: "";
}

.material-icons.attach_email:before {
  content: "";
}

.material-icons.attach_file:before {
  content: "";
}

.material-icons.attach_money:before {
  content: "";
}

.material-icons.attachment:before {
  content: "";
}

.material-icons.attractions:before {
  content: "";
}

.material-icons.attribution:before {
  content: "";
}

.material-icons.audio_file:before {
  content: "";
}

.material-icons.audiotrack:before {
  content: "";
}

.material-icons.auto_awesome:before {
  content: "";
}

.material-icons.auto_awesome_mosaic:before {
  content: "";
}

.material-icons.auto_awesome_motion:before {
  content: "";
}

.material-icons.auto_delete:before {
  content: "";
}

.material-icons.auto_fix_high:before {
  content: "";
}

.material-icons.auto_fix_normal:before {
  content: "";
}

.material-icons.auto_fix_off:before {
  content: "";
}

.material-icons.auto_graph:before {
  content: "";
}

.material-icons.auto_mode:before {
  content: "";
}

.material-icons.auto_stories:before {
  content: "";
}

.material-icons.autofps_select:before {
  content: "";
}

.material-icons.autorenew:before {
  content: "";
}

.material-icons.av_timer:before {
  content: "";
}

.material-icons.baby_changing_station:before {
  content: "";
}

.material-icons.back_hand:before {
  content: "";
}

.material-icons.backpack:before {
  content: "";
}

.material-icons.backspace:before {
  content: "";
}

.material-icons.backup:before {
  content: "";
}

.material-icons.backup_table:before {
  content: "";
}

.material-icons.badge:before {
  content: "";
}

.material-icons.bakery_dining:before {
  content: "";
}

.material-icons.balance:before {
  content: "";
}

.material-icons.balcony:before {
  content: "";
}

.material-icons.ballot:before {
  content: "";
}

.material-icons.bar_chart:before {
  content: "";
}

.material-icons.batch_prediction:before {
  content: "";
}

.material-icons.bathroom:before {
  content: "";
}

.material-icons.bathtub:before {
  content: "";
}

.material-icons.battery_0_bar:before {
  content: "";
}

.material-icons.battery_1_bar:before {
  content: "";
}

.material-icons.battery_2_bar:before {
  content: "";
}

.material-icons.battery_3_bar:before {
  content: "";
}

.material-icons.battery_4_bar:before {
  content: "";
}

.material-icons.battery_5_bar:before {
  content: "";
}

.material-icons.battery_6_bar:before {
  content: "";
}

.material-icons.battery_alert:before {
  content: "";
}

.material-icons.battery_charging_full:before {
  content: "";
}

.material-icons.battery_full:before {
  content: "";
}

.material-icons.battery_saver:before {
  content: "";
}

.material-icons.battery_std:before {
  content: "";
}

.material-icons.battery_unknown:before {
  content: "";
}

.material-icons.beach_access:before {
  content: "";
}

.material-icons.bed:before {
  content: "";
}

.material-icons.bedroom_baby:before {
  content: "";
}

.material-icons.bedroom_child:before {
  content: "";
}

.material-icons.bedroom_parent:before {
  content: "";
}

.material-icons.bedtime:before {
  content: "";
}

.material-icons.bedtime_off:before {
  content: "";
}

.material-icons.beenhere:before {
  content: "";
}

.material-icons.bento:before {
  content: "";
}

.material-icons.bike_scooter:before {
  content: "";
}

.material-icons.biotech:before {
  content: "";
}

.material-icons.blender:before {
  content: "";
}

.material-icons.blinds:before {
  content: "";
}

.material-icons.blinds_closed:before {
  content: "";
}

.material-icons.block:before {
  content: "";
}

.material-icons.block_flipped:before {
  content: "";
}

.material-icons.bloodtype:before {
  content: "";
}

.material-icons.bluetooth:before {
  content: "";
}

.material-icons.bluetooth_audio:before {
  content: "";
}

.material-icons.bluetooth_connected:before {
  content: "";
}

.material-icons.bluetooth_disabled:before {
  content: "";
}

.material-icons.bluetooth_drive:before {
  content: "";
}

.material-icons.bluetooth_searching:before {
  content: "";
}

.material-icons.blur_circular:before {
  content: "";
}

.material-icons.blur_linear:before {
  content: "";
}

.material-icons.blur_off:before {
  content: "";
}

.material-icons.blur_on:before {
  content: "";
}

.material-icons.bolt:before {
  content: "";
}

.material-icons.book:before {
  content: "";
}

.material-icons.book_online:before {
  content: "";
}

.material-icons.bookmark:before {
  content: "";
}

.material-icons.bookmark_add:before {
  content: "";
}

.material-icons.bookmark_added:before {
  content: "";
}

.material-icons.bookmark_border:before, .material-icons.bookmark_outline:before {
  content: "";
}

.material-icons.bookmark_remove:before {
  content: "";
}

.material-icons.bookmarks:before {
  content: "";
}

.material-icons.border_all:before {
  content: "";
}

.material-icons.border_bottom:before {
  content: "";
}

.material-icons.border_clear:before {
  content: "";
}

.material-icons.border_color:before {
  content: "";
}

.material-icons.border_horizontal:before {
  content: "";
}

.material-icons.border_inner:before {
  content: "";
}

.material-icons.border_left:before {
  content: "";
}

.material-icons.border_outer:before {
  content: "";
}

.material-icons.border_right:before {
  content: "";
}

.material-icons.border_style:before {
  content: "";
}

.material-icons.border_top:before {
  content: "";
}

.material-icons.border_vertical:before {
  content: "";
}

.material-icons.boy:before {
  content: "";
}

.material-icons.branding_watermark:before {
  content: "";
}

.material-icons.breakfast_dining:before {
  content: "";
}

.material-icons.brightness_1:before {
  content: "";
}

.material-icons.brightness_2:before {
  content: "";
}

.material-icons.brightness_3:before {
  content: "";
}

.material-icons.brightness_4:before {
  content: "";
}

.material-icons.brightness_5:before {
  content: "";
}

.material-icons.brightness_6:before {
  content: "";
}

.material-icons.brightness_7:before {
  content: "";
}

.material-icons.brightness_auto:before {
  content: "";
}

.material-icons.brightness_high:before {
  content: "";
}

.material-icons.brightness_low:before {
  content: "";
}

.material-icons.brightness_medium:before {
  content: "";
}

.material-icons.broadcast_on_home:before {
  content: "";
}

.material-icons.broadcast_on_personal:before {
  content: "";
}

.material-icons.broken_image:before {
  content: "";
}

.material-icons.browse_gallery:before {
  content: "";
}

.material-icons.browser_not_supported:before {
  content: "";
}

.material-icons.browser_updated:before {
  content: "";
}

.material-icons.brunch_dining:before {
  content: "";
}

.material-icons.brush:before {
  content: "";
}

.material-icons.bubble_chart:before {
  content: "";
}

.material-icons.bug_report:before {
  content: "";
}

.material-icons.build:before {
  content: "";
}

.material-icons.build_circle:before {
  content: "";
}

.material-icons.bungalow:before {
  content: "";
}

.material-icons.burst_mode:before {
  content: "";
}

.material-icons.bus_alert:before {
  content: "";
}

.material-icons.business:before {
  content: "";
}

.material-icons.business_center:before {
  content: "";
}

.material-icons.cabin:before {
  content: "";
}

.material-icons.cable:before {
  content: "";
}

.material-icons.cached:before {
  content: "";
}

.material-icons.cake:before {
  content: "";
}

.material-icons.calculate:before {
  content: "";
}

.material-icons.calendar_month:before {
  content: "";
}

.material-icons.calendar_today:before {
  content: "";
}

.material-icons.calendar_view_day:before {
  content: "";
}

.material-icons.calendar_view_month:before {
  content: "";
}

.material-icons.calendar_view_week:before {
  content: "";
}

.material-icons.call:before {
  content: "";
}

.material-icons.call_end:before {
  content: "";
}

.material-icons.call_made:before {
  content: "";
}

.material-icons.call_merge:before {
  content: "";
}

.material-icons.call_missed:before {
  content: "";
}

.material-icons.call_missed_outgoing:before {
  content: "";
}

.material-icons.call_received:before {
  content: "";
}

.material-icons.call_split:before {
  content: "";
}

.material-icons.call_to_action:before {
  content: "";
}

.material-icons.camera:before {
  content: "";
}

.material-icons.camera_alt:before {
  content: "";
}

.material-icons.camera_enhance:before {
  content: "";
}

.material-icons.camera_front:before {
  content: "";
}

.material-icons.camera_indoor:before {
  content: "";
}

.material-icons.camera_outdoor:before {
  content: "";
}

.material-icons.camera_rear:before {
  content: "";
}

.material-icons.camera_roll:before {
  content: "";
}

.material-icons.cameraswitch:before {
  content: "";
}

.material-icons.campaign:before {
  content: "";
}

.material-icons.cancel:before {
  content: "";
}

.material-icons.cancel_presentation:before {
  content: "";
}

.material-icons.cancel_schedule_send:before {
  content: "";
}

.material-icons.candlestick_chart:before {
  content: "";
}

.material-icons.car_crash:before {
  content: "";
}

.material-icons.car_rental:before {
  content: "";
}

.material-icons.car_repair:before {
  content: "";
}

.material-icons.card_giftcard:before {
  content: "";
}

.material-icons.card_membership:before {
  content: "";
}

.material-icons.card_travel:before {
  content: "";
}

.material-icons.carpenter:before {
  content: "";
}

.material-icons.cases:before {
  content: "";
}

.material-icons.casino:before {
  content: "";
}

.material-icons.cast:before {
  content: "";
}

.material-icons.cast_connected:before {
  content: "";
}

.material-icons.cast_for_education:before {
  content: "";
}

.material-icons.castle:before {
  content: "";
}

.material-icons.catching_pokemon:before {
  content: "";
}

.material-icons.category:before {
  content: "";
}

.material-icons.celebration:before {
  content: "";
}

.material-icons.cell_tower:before {
  content: "";
}

.material-icons.cell_wifi:before {
  content: "";
}

.material-icons.center_focus_strong:before {
  content: "";
}

.material-icons.center_focus_weak:before {
  content: "";
}

.material-icons.chair:before {
  content: "";
}

.material-icons.chair_alt:before {
  content: "";
}

.material-icons.chalet:before {
  content: "";
}

.material-icons.change_circle:before {
  content: "";
}

.material-icons.change_history:before {
  content: "";
}

.material-icons.charging_station:before {
  content: "";
}

.material-icons.chat:before {
  content: "";
}

.material-icons.chat_bubble:before {
  content: "";
}

.material-icons.chat_bubble_outline:before {
  content: "";
}

.material-icons.check:before {
  content: "";
}

.material-icons.check_box:before {
  content: "";
}

.material-icons.check_box_outline_blank:before {
  content: "";
}

.material-icons.check_circle:before {
  content: "";
}

.material-icons.check_circle_outline:before {
  content: "";
}

.material-icons.checklist:before {
  content: "";
}

.material-icons.checklist_rtl:before {
  content: "";
}

.material-icons.checkroom:before {
  content: "";
}

.material-icons.chevron_left:before {
  content: "";
}

.material-icons.chevron_right:before {
  content: "";
}

.material-icons.child_care:before {
  content: "";
}

.material-icons.child_friendly:before {
  content: "";
}

.material-icons.chrome_reader_mode:before {
  content: "";
}

.material-icons.church:before {
  content: "";
}

.material-icons.circle:before {
  content: "";
}

.material-icons.circle_notifications:before {
  content: "";
}

.material-icons.class:before {
  content: "";
}

.material-icons.clean_hands:before {
  content: "";
}

.material-icons.cleaning_services:before {
  content: "";
}

.material-icons.clear:before {
  content: "";
}

.material-icons.clear_all:before {
  content: "";
}

.material-icons.close:before {
  content: "";
}

.material-icons.close_fullscreen:before {
  content: "";
}

.material-icons.closed_caption:before {
  content: "";
}

.material-icons.closed_caption_disabled:before {
  content: "";
}

.material-icons.closed_caption_off:before {
  content: "";
}

.material-icons.cloud:before {
  content: "";
}

.material-icons.cloud_circle:before {
  content: "";
}

.material-icons.cloud_done:before {
  content: "";
}

.material-icons.cloud_download:before {
  content: "";
}

.material-icons.cloud_off:before {
  content: "";
}

.material-icons.cloud_queue:before {
  content: "";
}

.material-icons.cloud_sync:before {
  content: "";
}

.material-icons.cloud_upload:before {
  content: "";
}

.material-icons.cloudy_snowing:before {
  content: "";
}

.material-icons.co2:before {
  content: "";
}

.material-icons.co_present:before {
  content: "";
}

.material-icons.code:before {
  content: "";
}

.material-icons.code_off:before {
  content: "";
}

.material-icons.coffee:before {
  content: "";
}

.material-icons.coffee_maker:before {
  content: "";
}

.material-icons.collections:before {
  content: "";
}

.material-icons.collections_bookmark:before {
  content: "";
}

.material-icons.color_lens:before {
  content: "";
}

.material-icons.colorize:before {
  content: "";
}

.material-icons.comment:before {
  content: "";
}

.material-icons.comment_bank:before {
  content: "";
}

.material-icons.comments_disabled:before {
  content: "";
}

.material-icons.commit:before {
  content: "";
}

.material-icons.commute:before {
  content: "";
}

.material-icons.compare:before {
  content: "";
}

.material-icons.compare_arrows:before {
  content: "";
}

.material-icons.compass_calibration:before {
  content: "";
}

.material-icons.compost:before {
  content: "";
}

.material-icons.compress:before {
  content: "";
}

.material-icons.computer:before {
  content: "";
}

.material-icons.confirmation_num:before, .material-icons.confirmation_number:before {
  content: "";
}

.material-icons.connect_without_contact:before {
  content: "";
}

.material-icons.connected_tv:before {
  content: "";
}

.material-icons.connecting_airports:before {
  content: "";
}

.material-icons.construction:before {
  content: "";
}

.material-icons.contact_mail:before {
  content: "";
}

.material-icons.contact_page:before {
  content: "";
}

.material-icons.contact_phone:before {
  content: "";
}

.material-icons.contact_support:before {
  content: "";
}

.material-icons.contactless:before {
  content: "";
}

.material-icons.contacts:before {
  content: "";
}

.material-icons.content_copy:before {
  content: "";
}

.material-icons.content_cut:before {
  content: "";
}

.material-icons.content_paste:before {
  content: "";
}

.material-icons.content_paste_go:before {
  content: "";
}

.material-icons.content_paste_off:before {
  content: "";
}

.material-icons.content_paste_search:before {
  content: "";
}

.material-icons.contrast:before {
  content: "";
}

.material-icons.control_camera:before {
  content: "";
}

.material-icons.control_point:before {
  content: "";
}

.material-icons.control_point_duplicate:before {
  content: "";
}

.material-icons.cookie:before {
  content: "";
}

.material-icons.copy_all:before {
  content: "";
}

.material-icons.copyright:before {
  content: "";
}

.material-icons.coronavirus:before {
  content: "";
}

.material-icons.corporate_fare:before {
  content: "";
}

.material-icons.cottage:before {
  content: "";
}

.material-icons.countertops:before {
  content: "";
}

.material-icons.create:before {
  content: "";
}

.material-icons.create_new_folder:before {
  content: "";
}

.material-icons.credit_card:before {
  content: "";
}

.material-icons.credit_card_off:before {
  content: "";
}

.material-icons.credit_score:before {
  content: "";
}

.material-icons.crib:before {
  content: "";
}

.material-icons.crisis_alert:before {
  content: "";
}

.material-icons.crop:before {
  content: "";
}

.material-icons.crop_16_9:before {
  content: "";
}

.material-icons.crop_3_2:before {
  content: "";
}

.material-icons.crop_5_4:before {
  content: "";
}

.material-icons.crop_7_5:before {
  content: "";
}

.material-icons.crop_din:before {
  content: "";
}

.material-icons.crop_free:before {
  content: "";
}

.material-icons.crop_landscape:before {
  content: "";
}

.material-icons.crop_original:before {
  content: "";
}

.material-icons.crop_portrait:before {
  content: "";
}

.material-icons.crop_rotate:before {
  content: "";
}

.material-icons.crop_square:before {
  content: "";
}

.material-icons.cruelty_free:before {
  content: "";
}

.material-icons.css:before {
  content: "";
}

.material-icons.currency_bitcoin:before {
  content: "";
}

.material-icons.currency_exchange:before {
  content: "";
}

.material-icons.currency_franc:before {
  content: "";
}

.material-icons.currency_lira:before {
  content: "";
}

.material-icons.currency_pound:before {
  content: "";
}

.material-icons.currency_ruble:before {
  content: "";
}

.material-icons.currency_rupee:before {
  content: "";
}

.material-icons.currency_yen:before {
  content: "";
}

.material-icons.currency_yuan:before {
  content: "";
}

.material-icons.curtains:before {
  content: "";
}

.material-icons.curtains_closed:before {
  content: "";
}

.material-icons.cyclone:before {
  content: "";
}

.material-icons.dangerous:before {
  content: "";
}

.material-icons.dark_mode:before {
  content: "";
}

.material-icons.dashboard:before {
  content: "";
}

.material-icons.dashboard_customize:before {
  content: "";
}

.material-icons.data_array:before {
  content: "";
}

.material-icons.data_exploration:before {
  content: "";
}

.material-icons.data_object:before {
  content: "";
}

.material-icons.data_saver_off:before {
  content: "";
}

.material-icons.data_saver_on:before {
  content: "";
}

.material-icons.data_thresholding:before {
  content: "";
}

.material-icons.data_usage:before {
  content: "";
}

.material-icons.dataset:before {
  content: "";
}

.material-icons.dataset_linked:before {
  content: "";
}

.material-icons.date_range:before {
  content: "";
}

.material-icons.deblur:before {
  content: "";
}

.material-icons.deck:before {
  content: "";
}

.material-icons.dehaze:before {
  content: "";
}

.material-icons.delete:before {
  content: "";
}

.material-icons.delete_forever:before {
  content: "";
}

.material-icons.delete_outline:before {
  content: "";
}

.material-icons.delete_sweep:before {
  content: "";
}

.material-icons.delivery_dining:before {
  content: "";
}

.material-icons.density_large:before {
  content: "";
}

.material-icons.density_medium:before {
  content: "";
}

.material-icons.density_small:before {
  content: "";
}

.material-icons.departure_board:before {
  content: "";
}

.material-icons.description:before {
  content: "";
}

.material-icons.deselect:before {
  content: "";
}

.material-icons.design_services:before {
  content: "";
}

.material-icons.desk:before {
  content: "";
}

.material-icons.desktop_access_disabled:before {
  content: "";
}

.material-icons.desktop_mac:before {
  content: "";
}

.material-icons.desktop_windows:before {
  content: "";
}

.material-icons.details:before {
  content: "";
}

.material-icons.developer_board:before {
  content: "";
}

.material-icons.developer_board_off:before {
  content: "";
}

.material-icons.developer_mode:before {
  content: "";
}

.material-icons.device_hub:before {
  content: "";
}

.material-icons.device_thermostat:before {
  content: "";
}

.material-icons.device_unknown:before {
  content: "";
}

.material-icons.devices:before {
  content: "";
}

.material-icons.devices_fold:before {
  content: "";
}

.material-icons.devices_other:before {
  content: "";
}

.material-icons.dialer_sip:before {
  content: "";
}

.material-icons.dialpad:before {
  content: "";
}

.material-icons.diamond:before {
  content: "";
}

.material-icons.difference:before {
  content: "";
}

.material-icons.dining:before {
  content: "";
}

.material-icons.dinner_dining:before {
  content: "";
}

.material-icons.directions:before {
  content: "";
}

.material-icons.directions_bike:before {
  content: "";
}

.material-icons.directions_boat:before {
  content: "";
}

.material-icons.directions_boat_filled:before {
  content: "";
}

.material-icons.directions_bus:before {
  content: "";
}

.material-icons.directions_bus_filled:before {
  content: "";
}

.material-icons.directions_car:before {
  content: "";
}

.material-icons.directions_car_filled:before {
  content: "";
}

.material-icons.directions_ferry:before {
  content: "";
}

.material-icons.directions_off:before {
  content: "";
}

.material-icons.directions_railway:before {
  content: "";
}

.material-icons.directions_railway_filled:before {
  content: "";
}

.material-icons.directions_run:before {
  content: "";
}

.material-icons.directions_subway:before {
  content: "";
}

.material-icons.directions_subway_filled:before {
  content: "";
}

.material-icons.directions_train:before {
  content: "";
}

.material-icons.directions_transit:before {
  content: "";
}

.material-icons.directions_transit_filled:before {
  content: "";
}

.material-icons.directions_walk:before {
  content: "";
}

.material-icons.dirty_lens:before {
  content: "";
}

.material-icons.disabled_by_default:before {
  content: "";
}

.material-icons.disabled_visible:before {
  content: "";
}

.material-icons.disc_full:before {
  content: "";
}

.material-icons.discord:before {
  content: "";
}

.material-icons.discount:before {
  content: "";
}

.material-icons.display_settings:before {
  content: "";
}

.material-icons.dnd_forwardslash:before {
  content: "";
}

.material-icons.dns:before {
  content: "";
}

.material-icons.do_disturb:before {
  content: "";
}

.material-icons.do_disturb_alt:before {
  content: "";
}

.material-icons.do_disturb_off:before {
  content: "";
}

.material-icons.do_disturb_on:before {
  content: "";
}

.material-icons.do_not_disturb:before {
  content: "";
}

.material-icons.do_not_disturb_alt:before {
  content: "";
}

.material-icons.do_not_disturb_off:before {
  content: "";
}

.material-icons.do_not_disturb_on:before {
  content: "";
}

.material-icons.do_not_disturb_on_total_silence:before {
  content: "";
}

.material-icons.do_not_step:before {
  content: "";
}

.material-icons.do_not_touch:before {
  content: "";
}

.material-icons.dock:before {
  content: "";
}

.material-icons.document_scanner:before {
  content: "";
}

.material-icons.domain:before {
  content: "";
}

.material-icons.domain_add:before {
  content: "";
}

.material-icons.domain_disabled:before {
  content: "";
}

.material-icons.domain_verification:before {
  content: "";
}

.material-icons.done:before {
  content: "";
}

.material-icons.done_all:before {
  content: "";
}

.material-icons.done_outline:before {
  content: "";
}

.material-icons.donut_large:before {
  content: "";
}

.material-icons.donut_small:before {
  content: "";
}

.material-icons.door_back:before {
  content: "";
}

.material-icons.door_front:before {
  content: "";
}

.material-icons.door_sliding:before {
  content: "";
}

.material-icons.doorbell:before {
  content: "";
}

.material-icons.double_arrow:before {
  content: "";
}

.material-icons.downhill_skiing:before {
  content: "";
}

.material-icons.download:before {
  content: "";
}

.material-icons.download_done:before {
  content: "";
}

.material-icons.download_for_offline:before {
  content: "";
}

.material-icons.downloading:before {
  content: "";
}

.material-icons.drafts:before {
  content: "";
}

.material-icons.drag_handle:before {
  content: "";
}

.material-icons.drag_indicator:before {
  content: "";
}

.material-icons.draw:before {
  content: "";
}

.material-icons.drive_eta:before {
  content: "";
}

.material-icons.drive_file_move:before {
  content: "";
}

.material-icons.drive_file_move_outline:before {
  content: "";
}

.material-icons.drive_file_move_rtl:before {
  content: "";
}

.material-icons.drive_file_rename_outline:before {
  content: "";
}

.material-icons.drive_folder_upload:before {
  content: "";
}

.material-icons.dry:before {
  content: "";
}

.material-icons.dry_cleaning:before {
  content: "";
}

.material-icons.duo:before {
  content: "";
}

.material-icons.dvr:before {
  content: "";
}

.material-icons.dynamic_feed:before {
  content: "";
}

.material-icons.dynamic_form:before {
  content: "";
}

.material-icons.e_mobiledata:before {
  content: "";
}

.material-icons.earbuds:before {
  content: "";
}

.material-icons.earbuds_battery:before {
  content: "";
}

.material-icons.east:before {
  content: "";
}

.material-icons.eco:before {
  content: "";
}

.material-icons.edgesensor_high:before {
  content: "";
}

.material-icons.edgesensor_low:before {
  content: "";
}

.material-icons.edit:before {
  content: "";
}

.material-icons.edit_attributes:before {
  content: "";
}

.material-icons.edit_calendar:before {
  content: "";
}

.material-icons.edit_location:before {
  content: "";
}

.material-icons.edit_location_alt:before {
  content: "";
}

.material-icons.edit_note:before {
  content: "";
}

.material-icons.edit_notifications:before {
  content: "";
}

.material-icons.edit_off:before {
  content: "";
}

.material-icons.edit_road:before {
  content: "";
}

.material-icons.egg:before {
  content: "";
}

.material-icons.egg_alt:before {
  content: "";
}

.material-icons.eject:before {
  content: "";
}

.material-icons.elderly:before {
  content: "";
}

.material-icons.elderly_woman:before {
  content: "";
}

.material-icons.electric_bike:before {
  content: "";
}

.material-icons.electric_bolt:before {
  content: "";
}

.material-icons.electric_car:before {
  content: "";
}

.material-icons.electric_meter:before {
  content: "";
}

.material-icons.electric_moped:before {
  content: "";
}

.material-icons.electric_rickshaw:before {
  content: "";
}

.material-icons.electric_scooter:before {
  content: "";
}

.material-icons.electrical_services:before {
  content: "";
}

.material-icons.elevator:before {
  content: "";
}

.material-icons.email:before {
  content: "";
}

.material-icons.emergency:before {
  content: "";
}

.material-icons.emergency_recording:before {
  content: "";
}

.material-icons.emergency_share:before {
  content: "";
}

.material-icons.emoji_emotions:before {
  content: "";
}

.material-icons.emoji_events:before {
  content: "";
}

.material-icons.emoji_flags:before {
  content: "";
}

.material-icons.emoji_food_beverage:before {
  content: "";
}

.material-icons.emoji_nature:before {
  content: "";
}

.material-icons.emoji_objects:before {
  content: "";
}

.material-icons.emoji_people:before {
  content: "";
}

.material-icons.emoji_symbols:before {
  content: "";
}

.material-icons.emoji_transportation:before {
  content: "";
}

.material-icons.energy_savings_leaf:before {
  content: "";
}

.material-icons.engineering:before {
  content: "";
}

.material-icons.enhance_photo_translate:before {
  content: "";
}

.material-icons.enhanced_encryption:before {
  content: "";
}

.material-icons.equalizer:before {
  content: "";
}

.material-icons.error:before {
  content: "";
}

.material-icons.error_outline:before {
  content: "";
}

.material-icons.escalator:before {
  content: "";
}

.material-icons.escalator_warning:before {
  content: "";
}

.material-icons.euro:before {
  content: "";
}

.material-icons.euro_symbol:before {
  content: "";
}

.material-icons.ev_station:before {
  content: "";
}

.material-icons.event:before {
  content: "";
}

.material-icons.event_available:before {
  content: "";
}

.material-icons.event_busy:before {
  content: "";
}

.material-icons.event_note:before {
  content: "";
}

.material-icons.event_repeat:before {
  content: "";
}

.material-icons.event_seat:before {
  content: "";
}

.material-icons.exit_to_app:before {
  content: "";
}

.material-icons.expand:before {
  content: "";
}

.material-icons.expand_circle_down:before {
  content: "";
}

.material-icons.expand_less:before {
  content: "";
}

.material-icons.expand_more:before {
  content: "";
}

.material-icons.explicit:before {
  content: "";
}

.material-icons.explore:before {
  content: "";
}

.material-icons.explore_off:before {
  content: "";
}

.material-icons.exposure:before {
  content: "";
}

.material-icons.exposure_minus_1:before {
  content: "";
}

.material-icons.exposure_minus_2:before {
  content: "";
}

.material-icons.exposure_neg_1:before {
  content: "";
}

.material-icons.exposure_neg_2:before {
  content: "";
}

.material-icons.exposure_plus_1:before {
  content: "";
}

.material-icons.exposure_plus_2:before {
  content: "";
}

.material-icons.exposure_zero:before {
  content: "";
}

.material-icons.extension:before {
  content: "";
}

.material-icons.extension_off:before {
  content: "";
}

.material-icons.face:before {
  content: "";
}

.material-icons.face_retouching_natural:before {
  content: "";
}

.material-icons.face_retouching_off:before {
  content: "";
}

.material-icons.facebook:before {
  content: "";
}

.material-icons.fact_check:before {
  content: "";
}

.material-icons.factory:before {
  content: "";
}

.material-icons.family_restroom:before {
  content: "";
}

.material-icons.fast_forward:before {
  content: "";
}

.material-icons.fast_rewind:before {
  content: "";
}

.material-icons.fastfood:before {
  content: "";
}

.material-icons.favorite:before {
  content: "";
}

.material-icons.favorite_border:before, .material-icons.favorite_outline:before {
  content: "";
}

.material-icons.fax:before {
  content: "";
}

.material-icons.featured_play_list:before {
  content: "";
}

.material-icons.featured_video:before {
  content: "";
}

.material-icons.feed:before {
  content: "";
}

.material-icons.feedback:before {
  content: "";
}

.material-icons.female:before {
  content: "";
}

.material-icons.fence:before {
  content: "";
}

.material-icons.festival:before {
  content: "";
}

.material-icons.fiber_dvr:before {
  content: "";
}

.material-icons.fiber_manual_record:before {
  content: "";
}

.material-icons.fiber_new:before {
  content: "";
}

.material-icons.fiber_pin:before {
  content: "";
}

.material-icons.fiber_smart_record:before {
  content: "";
}

.material-icons.file_copy:before {
  content: "";
}

.material-icons.file_download:before {
  content: "";
}

.material-icons.file_download_done:before {
  content: "";
}

.material-icons.file_download_off:before {
  content: "";
}

.material-icons.file_open:before {
  content: "";
}

.material-icons.file_present:before {
  content: "";
}

.material-icons.file_upload:before {
  content: "";
}

.material-icons.filter:before {
  content: "";
}

.material-icons.filter_1:before {
  content: "";
}

.material-icons.filter_2:before {
  content: "";
}

.material-icons.filter_3:before {
  content: "";
}

.material-icons.filter_4:before {
  content: "";
}

.material-icons.filter_5:before {
  content: "";
}

.material-icons.filter_6:before {
  content: "";
}

.material-icons.filter_7:before {
  content: "";
}

.material-icons.filter_8:before {
  content: "";
}

.material-icons.filter_9:before {
  content: "";
}

.material-icons.filter_9_plus:before {
  content: "";
}

.material-icons.filter_alt:before {
  content: "";
}

.material-icons.filter_alt_off:before {
  content: "";
}

.material-icons.filter_b_and_w:before {
  content: "";
}

.material-icons.filter_center_focus:before {
  content: "";
}

.material-icons.filter_drama:before {
  content: "";
}

.material-icons.filter_frames:before {
  content: "";
}

.material-icons.filter_hdr:before {
  content: "";
}

.material-icons.filter_list:before {
  content: "";
}

.material-icons.filter_list_alt:before {
  content: "";
}

.material-icons.filter_list_off:before {
  content: "";
}

.material-icons.filter_none:before {
  content: "";
}

.material-icons.filter_tilt_shift:before {
  content: "";
}

.material-icons.filter_vintage:before {
  content: "";
}

.material-icons.find_in_page:before {
  content: "";
}

.material-icons.find_replace:before {
  content: "";
}

.material-icons.fingerprint:before {
  content: "";
}

.material-icons.fire_extinguisher:before {
  content: "";
}

.material-icons.fire_hydrant:before {
  content: "";
}

.material-icons.fire_hydrant_alt:before {
  content: "";
}

.material-icons.fire_truck:before {
  content: "";
}

.material-icons.fireplace:before {
  content: "";
}

.material-icons.first_page:before {
  content: "";
}

.material-icons.fit_screen:before {
  content: "";
}

.material-icons.fitbit:before {
  content: "";
}

.material-icons.fitness_center:before {
  content: "";
}

.material-icons.flag:before {
  content: "";
}

.material-icons.flag_circle:before {
  content: "";
}

.material-icons.flaky:before {
  content: "";
}

.material-icons.flare:before {
  content: "";
}

.material-icons.flash_auto:before {
  content: "";
}

.material-icons.flash_off:before {
  content: "";
}

.material-icons.flash_on:before {
  content: "";
}

.material-icons.flashlight_off:before {
  content: "";
}

.material-icons.flashlight_on:before {
  content: "";
}

.material-icons.flatware:before {
  content: "";
}

.material-icons.flight:before {
  content: "";
}

.material-icons.flight_class:before {
  content: "";
}

.material-icons.flight_land:before {
  content: "";
}

.material-icons.flight_takeoff:before {
  content: "";
}

.material-icons.flip:before {
  content: "";
}

.material-icons.flip_camera_android:before {
  content: "";
}

.material-icons.flip_camera_ios:before {
  content: "";
}

.material-icons.flip_to_back:before {
  content: "";
}

.material-icons.flip_to_front:before {
  content: "";
}

.material-icons.flood:before {
  content: "";
}

.material-icons.flourescent:before {
  content: "";
}

.material-icons.flutter_dash:before {
  content: "";
}

.material-icons.fmd_bad:before {
  content: "";
}

.material-icons.fmd_good:before {
  content: "";
}

.material-icons.foggy:before {
  content: "";
}

.material-icons.folder:before {
  content: "";
}

.material-icons.folder_copy:before {
  content: "";
}

.material-icons.folder_delete:before {
  content: "";
}

.material-icons.folder_off:before {
  content: "";
}

.material-icons.folder_open:before {
  content: "";
}

.material-icons.folder_shared:before {
  content: "";
}

.material-icons.folder_special:before {
  content: "";
}

.material-icons.folder_zip:before {
  content: "";
}

.material-icons.follow_the_signs:before {
  content: "";
}

.material-icons.font_download:before {
  content: "";
}

.material-icons.font_download_off:before {
  content: "";
}

.material-icons.food_bank:before {
  content: "";
}

.material-icons.forest:before {
  content: "";
}

.material-icons.fork_left:before {
  content: "";
}

.material-icons.fork_right:before {
  content: "";
}

.material-icons.format_align_center:before {
  content: "";
}

.material-icons.format_align_justify:before {
  content: "";
}

.material-icons.format_align_left:before {
  content: "";
}

.material-icons.format_align_right:before {
  content: "";
}

.material-icons.format_bold:before {
  content: "";
}

.material-icons.format_clear:before {
  content: "";
}

.material-icons.format_color_fill:before {
  content: "";
}

.material-icons.format_color_reset:before {
  content: "";
}

.material-icons.format_color_text:before {
  content: "";
}

.material-icons.format_indent_decrease:before {
  content: "";
}

.material-icons.format_indent_increase:before {
  content: "";
}

.material-icons.format_italic:before {
  content: "";
}

.material-icons.format_line_spacing:before {
  content: "";
}

.material-icons.format_list_bulleted:before {
  content: "";
}

.material-icons.format_list_numbered:before {
  content: "";
}

.material-icons.format_list_numbered_rtl:before {
  content: "";
}

.material-icons.format_overline:before {
  content: "";
}

.material-icons.format_paint:before {
  content: "";
}

.material-icons.format_quote:before {
  content: "";
}

.material-icons.format_shapes:before {
  content: "";
}

.material-icons.format_size:before {
  content: "";
}

.material-icons.format_strikethrough:before {
  content: "";
}

.material-icons.format_textdirection_l_to_r:before {
  content: "";
}

.material-icons.format_textdirection_r_to_l:before {
  content: "";
}

.material-icons.format_underline:before, .material-icons.format_underlined:before {
  content: "";
}

.material-icons.fort:before {
  content: "";
}

.material-icons.forum:before {
  content: "";
}

.material-icons.forward:before {
  content: "";
}

.material-icons.forward_10:before {
  content: "";
}

.material-icons.forward_30:before {
  content: "";
}

.material-icons.forward_5:before {
  content: "";
}

.material-icons.forward_to_inbox:before {
  content: "";
}

.material-icons.foundation:before {
  content: "";
}

.material-icons.free_breakfast:before {
  content: "";
}

.material-icons.free_cancellation:before {
  content: "";
}

.material-icons.front_hand:before {
  content: "";
}

.material-icons.fullscreen:before {
  content: "";
}

.material-icons.fullscreen_exit:before {
  content: "";
}

.material-icons.functions:before {
  content: "";
}

.material-icons.g_mobiledata:before {
  content: "";
}

.material-icons.g_translate:before {
  content: "";
}

.material-icons.gamepad:before {
  content: "";
}

.material-icons.games:before {
  content: "";
}

.material-icons.garage:before {
  content: "";
}

.material-icons.gas_meter:before {
  content: "";
}

.material-icons.gavel:before {
  content: "";
}

.material-icons.generating_tokens:before {
  content: "";
}

.material-icons.gesture:before {
  content: "";
}

.material-icons.get_app:before {
  content: "";
}

.material-icons.gif:before {
  content: "";
}

.material-icons.gif_box:before {
  content: "";
}

.material-icons.girl:before {
  content: "";
}

.material-icons.gite:before {
  content: "";
}

.material-icons.goat:before {
  content: "";
}

.material-icons.golf_course:before {
  content: "";
}

.material-icons.gpp_bad:before {
  content: "";
}

.material-icons.gpp_good:before {
  content: "";
}

.material-icons.gpp_maybe:before {
  content: "";
}

.material-icons.gps_fixed:before {
  content: "";
}

.material-icons.gps_not_fixed:before {
  content: "";
}

.material-icons.gps_off:before {
  content: "";
}

.material-icons.grade:before {
  content: "";
}

.material-icons.gradient:before {
  content: "";
}

.material-icons.grading:before {
  content: "";
}

.material-icons.grain:before {
  content: "";
}

.material-icons.graphic_eq:before {
  content: "";
}

.material-icons.grass:before {
  content: "";
}

.material-icons.grid_3x3:before {
  content: "";
}

.material-icons.grid_4x4:before {
  content: "";
}

.material-icons.grid_goldenratio:before {
  content: "";
}

.material-icons.grid_off:before {
  content: "";
}

.material-icons.grid_on:before {
  content: "";
}

.material-icons.grid_view:before {
  content: "";
}

.material-icons.group:before {
  content: "";
}

.material-icons.group_add:before {
  content: "";
}

.material-icons.group_off:before {
  content: "";
}

.material-icons.group_remove:before {
  content: "";
}

.material-icons.group_work:before {
  content: "";
}

.material-icons.groups:before {
  content: "";
}

.material-icons.h_mobiledata:before {
  content: "";
}

.material-icons.h_plus_mobiledata:before {
  content: "";
}

.material-icons.hail:before {
  content: "";
}

.material-icons.handshake:before {
  content: "";
}

.material-icons.handyman:before {
  content: "";
}

.material-icons.hardware:before {
  content: "";
}

.material-icons.hd:before {
  content: "";
}

.material-icons.hdr_auto:before {
  content: "";
}

.material-icons.hdr_auto_select:before {
  content: "";
}

.material-icons.hdr_enhanced_select:before {
  content: "";
}

.material-icons.hdr_off:before {
  content: "";
}

.material-icons.hdr_off_select:before {
  content: "";
}

.material-icons.hdr_on:before {
  content: "";
}

.material-icons.hdr_on_select:before {
  content: "";
}

.material-icons.hdr_plus:before {
  content: "";
}

.material-icons.hdr_strong:before {
  content: "";
}

.material-icons.hdr_weak:before {
  content: "";
}

.material-icons.headphones:before {
  content: "";
}

.material-icons.headphones_battery:before {
  content: "";
}

.material-icons.headset:before {
  content: "";
}

.material-icons.headset_mic:before {
  content: "";
}

.material-icons.headset_off:before {
  content: "";
}

.material-icons.healing:before {
  content: "";
}

.material-icons.health_and_safety:before {
  content: "";
}

.material-icons.hearing:before {
  content: "";
}

.material-icons.hearing_disabled:before {
  content: "";
}

.material-icons.heart_broken:before {
  content: "";
}

.material-icons.heat_pump:before {
  content: "";
}

.material-icons.height:before {
  content: "";
}

.material-icons.help:before {
  content: "";
}

.material-icons.help_center:before {
  content: "";
}

.material-icons.help_outline:before {
  content: "";
}

.material-icons.hevc:before {
  content: "";
}

.material-icons.hexagon:before {
  content: "";
}

.material-icons.hide_image:before {
  content: "";
}

.material-icons.hide_source:before {
  content: "";
}

.material-icons.high_quality:before {
  content: "";
}

.material-icons.highlight:before {
  content: "";
}

.material-icons.highlight_alt:before {
  content: "";
}

.material-icons.highlight_off:before, .material-icons.highlight_remove:before {
  content: "";
}

.material-icons.hiking:before {
  content: "";
}

.material-icons.history:before {
  content: "";
}

.material-icons.history_edu:before {
  content: "";
}

.material-icons.history_toggle_off:before {
  content: "";
}

.material-icons.hive:before {
  content: "";
}

.material-icons.hls:before {
  content: "";
}

.material-icons.hls_off:before {
  content: "";
}

.material-icons.holiday_village:before {
  content: "";
}

.material-icons.home:before {
  content: "";
}

.material-icons.home_filled:before {
  content: "";
}

.material-icons.home_max:before {
  content: "";
}

.material-icons.home_mini:before {
  content: "";
}

.material-icons.home_repair_service:before {
  content: "";
}

.material-icons.home_work:before {
  content: "";
}

.material-icons.horizontal_distribute:before {
  content: "";
}

.material-icons.horizontal_rule:before {
  content: "";
}

.material-icons.horizontal_split:before {
  content: "";
}

.material-icons.hot_tub:before {
  content: "";
}

.material-icons.hotel:before {
  content: "";
}

.material-icons.hotel_class:before {
  content: "";
}

.material-icons.hourglass_bottom:before {
  content: "";
}

.material-icons.hourglass_disabled:before {
  content: "";
}

.material-icons.hourglass_empty:before {
  content: "";
}

.material-icons.hourglass_full:before {
  content: "";
}

.material-icons.hourglass_top:before {
  content: "";
}

.material-icons.house:before {
  content: "";
}

.material-icons.house_siding:before {
  content: "";
}

.material-icons.houseboat:before {
  content: "";
}

.material-icons.how_to_reg:before {
  content: "";
}

.material-icons.how_to_vote:before {
  content: "";
}

.material-icons.html:before {
  content: "";
}

.material-icons.http:before {
  content: "";
}

.material-icons.https:before {
  content: "";
}

.material-icons.hub:before {
  content: "";
}

.material-icons.hvac:before {
  content: "";
}

.material-icons.ice_skating:before {
  content: "";
}

.material-icons.icecream:before {
  content: "";
}

.material-icons.image:before {
  content: "";
}

.material-icons.image_aspect_ratio:before {
  content: "";
}

.material-icons.image_not_supported:before {
  content: "";
}

.material-icons.image_search:before {
  content: "";
}

.material-icons.imagesearch_roller:before {
  content: "";
}

.material-icons.import_contacts:before {
  content: "";
}

.material-icons.import_export:before {
  content: "";
}

.material-icons.important_devices:before {
  content: "";
}

.material-icons.inbox:before {
  content: "";
}

.material-icons.incomplete_circle:before {
  content: "";
}

.material-icons.indeterminate_check_box:before {
  content: "";
}

.material-icons.info:before {
  content: "";
}

.material-icons.info_outline:before {
  content: "";
}

.material-icons.input:before {
  content: "";
}

.material-icons.insert_chart:before {
  content: "";
}

.material-icons.insert_chart_outlined:before {
  content: "";
}

.material-icons.insert_comment:before {
  content: "";
}

.material-icons.insert_drive_file:before {
  content: "";
}

.material-icons.insert_emoticon:before {
  content: "";
}

.material-icons.insert_invitation:before {
  content: "";
}

.material-icons.insert_link:before {
  content: "";
}

.material-icons.insert_page_break:before {
  content: "";
}

.material-icons.insert_photo:before {
  content: "";
}

.material-icons.insights:before {
  content: "";
}

.material-icons.install_desktop:before {
  content: "";
}

.material-icons.install_mobile:before {
  content: "";
}

.material-icons.integration_instructions:before {
  content: "";
}

.material-icons.interests:before {
  content: "";
}

.material-icons.interpreter_mode:before {
  content: "";
}

.material-icons.inventory:before {
  content: "";
}

.material-icons.inventory_2:before {
  content: "";
}

.material-icons.invert_colors:before {
  content: "";
}

.material-icons.invert_colors_off:before {
  content: "";
}

.material-icons.invert_colors_on:before {
  content: "";
}

.material-icons.ios_share:before {
  content: "";
}

.material-icons.iron:before {
  content: "";
}

.material-icons.iso:before {
  content: "";
}

.material-icons.javascript:before {
  content: "";
}

.material-icons.join_full:before {
  content: "";
}

.material-icons.join_inner:before {
  content: "";
}

.material-icons.join_left:before {
  content: "";
}

.material-icons.join_right:before {
  content: "";
}

.material-icons.kayaking:before {
  content: "";
}

.material-icons.kebab_dining:before {
  content: "";
}

.material-icons.key:before {
  content: "";
}

.material-icons.key_off:before {
  content: "";
}

.material-icons.keyboard:before {
  content: "";
}

.material-icons.keyboard_alt:before {
  content: "";
}

.material-icons.keyboard_arrow_down:before {
  content: "";
}

.material-icons.keyboard_arrow_left:before {
  content: "";
}

.material-icons.keyboard_arrow_right:before {
  content: "";
}

.material-icons.keyboard_arrow_up:before {
  content: "";
}

.material-icons.keyboard_backspace:before {
  content: "";
}

.material-icons.keyboard_capslock:before {
  content: "";
}

.material-icons.keyboard_command:before {
  content: "";
}

.material-icons.keyboard_command_key:before {
  content: "";
}

.material-icons.keyboard_control:before {
  content: "";
}

.material-icons.keyboard_control_key:before {
  content: "";
}

.material-icons.keyboard_double_arrow_down:before {
  content: "";
}

.material-icons.keyboard_double_arrow_left:before {
  content: "";
}

.material-icons.keyboard_double_arrow_right:before {
  content: "";
}

.material-icons.keyboard_double_arrow_up:before {
  content: "";
}

.material-icons.keyboard_hide:before {
  content: "";
}

.material-icons.keyboard_option:before {
  content: "";
}

.material-icons.keyboard_option_key:before {
  content: "";
}

.material-icons.keyboard_return:before {
  content: "";
}

.material-icons.keyboard_tab:before {
  content: "";
}

.material-icons.keyboard_voice:before {
  content: "";
}

.material-icons.king_bed:before {
  content: "";
}

.material-icons.kitchen:before {
  content: "";
}

.material-icons.kitesurfing:before {
  content: "";
}

.material-icons.label:before {
  content: "";
}

.material-icons.label_important:before {
  content: "";
}

.material-icons.label_important_outline:before {
  content: "";
}

.material-icons.label_off:before {
  content: "";
}

.material-icons.label_outline:before {
  content: "";
}

.material-icons.lan:before {
  content: "";
}

.material-icons.landscape:before {
  content: "";
}

.material-icons.landslide:before {
  content: "";
}

.material-icons.language:before {
  content: "";
}

.material-icons.laptop:before {
  content: "";
}

.material-icons.laptop_chromebook:before {
  content: "";
}

.material-icons.laptop_mac:before {
  content: "";
}

.material-icons.laptop_windows:before {
  content: "";
}

.material-icons.last_page:before {
  content: "";
}

.material-icons.launch:before {
  content: "";
}

.material-icons.layers:before {
  content: "";
}

.material-icons.layers_clear:before {
  content: "";
}

.material-icons.leaderboard:before {
  content: "";
}

.material-icons.leak_add:before {
  content: "";
}

.material-icons.leak_remove:before {
  content: "";
}

.material-icons.leave_bags_at_home:before {
  content: "";
}

.material-icons.legend_toggle:before {
  content: "";
}

.material-icons.lens:before {
  content: "";
}

.material-icons.lens_blur:before {
  content: "";
}

.material-icons.library_add:before {
  content: "";
}

.material-icons.library_add_check:before {
  content: "";
}

.material-icons.library_books:before {
  content: "";
}

.material-icons.library_music:before {
  content: "";
}

.material-icons.light:before {
  content: "";
}

.material-icons.light_mode:before {
  content: "";
}

.material-icons.lightbulb:before {
  content: "";
}

.material-icons.lightbulb_circle:before {
  content: "";
}

.material-icons.lightbulb_outline:before {
  content: "";
}

.material-icons.line_axis:before {
  content: "";
}

.material-icons.line_style:before {
  content: "";
}

.material-icons.line_weight:before {
  content: "";
}

.material-icons.linear_scale:before {
  content: "";
}

.material-icons.link:before {
  content: "";
}

.material-icons.link_off:before {
  content: "";
}

.material-icons.linked_camera:before {
  content: "";
}

.material-icons.liquor:before {
  content: "";
}

.material-icons.list:before {
  content: "";
}

.material-icons.list_alt:before {
  content: "";
}

.material-icons.live_help:before {
  content: "";
}

.material-icons.live_tv:before {
  content: "";
}

.material-icons.living:before {
  content: "";
}

.material-icons.local_activity:before {
  content: "";
}

.material-icons.local_airport:before {
  content: "";
}

.material-icons.local_atm:before {
  content: "";
}

.material-icons.local_attraction:before {
  content: "";
}

.material-icons.local_bar:before {
  content: "";
}

.material-icons.local_cafe:before {
  content: "";
}

.material-icons.local_car_wash:before {
  content: "";
}

.material-icons.local_convenience_store:before {
  content: "";
}

.material-icons.local_dining:before {
  content: "";
}

.material-icons.local_drink:before {
  content: "";
}

.material-icons.local_fire_department:before {
  content: "";
}

.material-icons.local_florist:before {
  content: "";
}

.material-icons.local_gas_station:before {
  content: "";
}

.material-icons.local_grocery_store:before {
  content: "";
}

.material-icons.local_hospital:before {
  content: "";
}

.material-icons.local_hotel:before {
  content: "";
}

.material-icons.local_laundry_service:before {
  content: "";
}

.material-icons.local_library:before {
  content: "";
}

.material-icons.local_mall:before {
  content: "";
}

.material-icons.local_movies:before {
  content: "";
}

.material-icons.local_offer:before {
  content: "";
}

.material-icons.local_parking:before {
  content: "";
}

.material-icons.local_pharmacy:before {
  content: "";
}

.material-icons.local_phone:before {
  content: "";
}

.material-icons.local_pizza:before {
  content: "";
}

.material-icons.local_play:before {
  content: "";
}

.material-icons.local_police:before {
  content: "";
}

.material-icons.local_post_office:before {
  content: "";
}

.material-icons.local_print_shop:before, .material-icons.local_printshop:before {
  content: "";
}

.material-icons.local_restaurant:before {
  content: "";
}

.material-icons.local_see:before {
  content: "";
}

.material-icons.local_shipping:before {
  content: "";
}

.material-icons.local_taxi:before {
  content: "";
}

.material-icons.location_city:before {
  content: "";
}

.material-icons.location_disabled:before {
  content: "";
}

.material-icons.location_history:before {
  content: "";
}

.material-icons.location_off:before {
  content: "";
}

.material-icons.location_on:before {
  content: "";
}

.material-icons.location_pin:before {
  content: "";
}

.material-icons.location_searching:before {
  content: "";
}

.material-icons.lock:before {
  content: "";
}

.material-icons.lock_clock:before {
  content: "";
}

.material-icons.lock_open:before {
  content: "";
}

.material-icons.lock_outline:before {
  content: "";
}

.material-icons.lock_person:before {
  content: "";
}

.material-icons.lock_reset:before {
  content: "";
}

.material-icons.login:before {
  content: "";
}

.material-icons.logo_dev:before {
  content: "";
}

.material-icons.logout:before {
  content: "";
}

.material-icons.looks:before {
  content: "";
}

.material-icons.looks_3:before {
  content: "";
}

.material-icons.looks_4:before {
  content: "";
}

.material-icons.looks_5:before {
  content: "";
}

.material-icons.looks_6:before {
  content: "";
}

.material-icons.looks_one:before {
  content: "";
}

.material-icons.looks_two:before {
  content: "";
}

.material-icons.loop:before {
  content: "";
}

.material-icons.loupe:before {
  content: "";
}

.material-icons.low_priority:before {
  content: "";
}

.material-icons.loyalty:before {
  content: "";
}

.material-icons.lte_mobiledata:before {
  content: "";
}

.material-icons.lte_plus_mobiledata:before {
  content: "";
}

.material-icons.luggage:before {
  content: "";
}

.material-icons.lunch_dining:before {
  content: "";
}

.material-icons.lyrics:before {
  content: "";
}

.material-icons.mail:before {
  content: "";
}

.material-icons.mail_lock:before {
  content: "";
}

.material-icons.mail_outline:before {
  content: "";
}

.material-icons.male:before {
  content: "";
}

.material-icons.man:before {
  content: "";
}

.material-icons.manage_accounts:before {
  content: "";
}

.material-icons.manage_history:before {
  content: "";
}

.material-icons.manage_search:before {
  content: "";
}

.material-icons.map:before {
  content: "";
}

.material-icons.maps_home_work:before {
  content: "";
}

.material-icons.maps_ugc:before {
  content: "";
}

.material-icons.margin:before {
  content: "";
}

.material-icons.mark_as_unread:before {
  content: "";
}

.material-icons.mark_chat_read:before {
  content: "";
}

.material-icons.mark_chat_unread:before {
  content: "";
}

.material-icons.mark_email_read:before {
  content: "";
}

.material-icons.mark_email_unread:before {
  content: "";
}

.material-icons.mark_unread_chat_alt:before {
  content: "";
}

.material-icons.markunread:before {
  content: "";
}

.material-icons.markunread_mailbox:before {
  content: "";
}

.material-icons.masks:before {
  content: "";
}

.material-icons.maximize:before {
  content: "";
}

.material-icons.media_bluetooth_off:before {
  content: "";
}

.material-icons.media_bluetooth_on:before {
  content: "";
}

.material-icons.mediation:before {
  content: "";
}

.material-icons.medical_information:before {
  content: "";
}

.material-icons.medical_services:before {
  content: "";
}

.material-icons.medication:before {
  content: "";
}

.material-icons.medication_liquid:before {
  content: "";
}

.material-icons.meeting_room:before {
  content: "";
}

.material-icons.memory:before {
  content: "";
}

.material-icons.menu:before {
  content: "";
}

.material-icons.menu_book:before {
  content: "";
}

.material-icons.menu_open:before {
  content: "";
}

.material-icons.merge:before {
  content: "";
}

.material-icons.merge_type:before {
  content: "";
}

.material-icons.message:before {
  content: "";
}

.material-icons.messenger:before {
  content: "";
}

.material-icons.messenger_outline:before {
  content: "";
}

.material-icons.mic:before {
  content: "";
}

.material-icons.mic_external_off:before {
  content: "";
}

.material-icons.mic_external_on:before {
  content: "";
}

.material-icons.mic_none:before {
  content: "";
}

.material-icons.mic_off:before {
  content: "";
}

.material-icons.microwave:before {
  content: "";
}

.material-icons.military_tech:before {
  content: "";
}

.material-icons.minimize:before {
  content: "";
}

.material-icons.minor_crash:before {
  content: "";
}

.material-icons.miscellaneous_services:before {
  content: "";
}

.material-icons.missed_video_call:before {
  content: "";
}

.material-icons.mms:before {
  content: "";
}

.material-icons.mobile_friendly:before {
  content: "";
}

.material-icons.mobile_off:before {
  content: "";
}

.material-icons.mobile_screen_share:before {
  content: "";
}

.material-icons.mobiledata_off:before {
  content: "";
}

.material-icons.mode:before {
  content: "";
}

.material-icons.mode_comment:before {
  content: "";
}

.material-icons.mode_edit:before {
  content: "";
}

.material-icons.mode_edit_outline:before {
  content: "";
}

.material-icons.mode_fan_off:before {
  content: "";
}

.material-icons.mode_night:before {
  content: "";
}

.material-icons.mode_of_travel:before {
  content: "";
}

.material-icons.mode_standby:before {
  content: "";
}

.material-icons.model_training:before {
  content: "";
}

.material-icons.monetization_on:before {
  content: "";
}

.material-icons.money:before {
  content: "";
}

.material-icons.money_off:before {
  content: "";
}

.material-icons.money_off_csred:before {
  content: "";
}

.material-icons.monitor:before {
  content: "";
}

.material-icons.monitor_heart:before {
  content: "";
}

.material-icons.monitor_weight:before {
  content: "";
}

.material-icons.monochrome_photos:before {
  content: "";
}

.material-icons.mood:before {
  content: "";
}

.material-icons.mood_bad:before {
  content: "";
}

.material-icons.moped:before {
  content: "";
}

.material-icons.more:before {
  content: "";
}

.material-icons.more_horiz:before {
  content: "";
}

.material-icons.more_time:before {
  content: "";
}

.material-icons.more_vert:before {
  content: "";
}

.material-icons.mosque:before {
  content: "";
}

.material-icons.motion_photos_auto:before {
  content: "";
}

.material-icons.motion_photos_off:before {
  content: "";
}

.material-icons.motion_photos_on:before {
  content: "";
}

.material-icons.motion_photos_pause:before {
  content: "";
}

.material-icons.motion_photos_paused:before {
  content: "";
}

.material-icons.motorcycle:before {
  content: "";
}

.material-icons.mouse:before {
  content: "";
}

.material-icons.move_down:before {
  content: "";
}

.material-icons.move_to_inbox:before {
  content: "";
}

.material-icons.move_up:before {
  content: "";
}

.material-icons.movie:before {
  content: "";
}

.material-icons.movie_creation:before {
  content: "";
}

.material-icons.movie_filter:before {
  content: "";
}

.material-icons.moving:before {
  content: "";
}

.material-icons.mp:before {
  content: "";
}

.material-icons.multiline_chart:before {
  content: "";
}

.material-icons.multiple_stop:before {
  content: "";
}

.material-icons.multitrack_audio:before {
  content: "";
}

.material-icons.museum:before {
  content: "";
}

.material-icons.music_note:before {
  content: "";
}

.material-icons.music_off:before {
  content: "";
}

.material-icons.music_video:before {
  content: "";
}

.material-icons.my_library_add:before {
  content: "";
}

.material-icons.my_library_books:before {
  content: "";
}

.material-icons.my_library_music:before {
  content: "";
}

.material-icons.my_location:before {
  content: "";
}

.material-icons.nat:before {
  content: "";
}

.material-icons.nature:before {
  content: "";
}

.material-icons.nature_people:before {
  content: "";
}

.material-icons.navigate_before:before {
  content: "";
}

.material-icons.navigate_next:before {
  content: "";
}

.material-icons.navigation:before {
  content: "";
}

.material-icons.near_me:before {
  content: "";
}

.material-icons.near_me_disabled:before {
  content: "";
}

.material-icons.nearby_error:before {
  content: "";
}

.material-icons.nearby_off:before {
  content: "";
}

.material-icons.nest_cam_wired_stand:before {
  content: "";
}

.material-icons.network_cell:before {
  content: "";
}

.material-icons.network_check:before {
  content: "";
}

.material-icons.network_locked:before {
  content: "";
}

.material-icons.network_ping:before {
  content: "";
}

.material-icons.network_wifi:before {
  content: "";
}

.material-icons.network_wifi_1_bar:before {
  content: "";
}

.material-icons.network_wifi_2_bar:before {
  content: "";
}

.material-icons.network_wifi_3_bar:before {
  content: "";
}

.material-icons.new_label:before {
  content: "";
}

.material-icons.new_releases:before {
  content: "";
}

.material-icons.newspaper:before {
  content: "";
}

.material-icons.next_plan:before {
  content: "";
}

.material-icons.next_week:before {
  content: "";
}

.material-icons.nfc:before {
  content: "";
}

.material-icons.night_shelter:before {
  content: "";
}

.material-icons.nightlife:before {
  content: "";
}

.material-icons.nightlight:before {
  content: "";
}

.material-icons.nightlight_round:before {
  content: "";
}

.material-icons.nights_stay:before {
  content: "";
}

.material-icons.no_accounts:before {
  content: "";
}

.material-icons.no_adult_content:before {
  content: "";
}

.material-icons.no_backpack:before {
  content: "";
}

.material-icons.no_cell:before {
  content: "";
}

.material-icons.no_crash:before {
  content: "";
}

.material-icons.no_drinks:before {
  content: "";
}

.material-icons.no_encryption:before {
  content: "";
}

.material-icons.no_encryption_gmailerrorred:before {
  content: "";
}

.material-icons.no_flash:before {
  content: "";
}

.material-icons.no_food:before {
  content: "";
}

.material-icons.no_luggage:before {
  content: "";
}

.material-icons.no_meals:before {
  content: "";
}

.material-icons.no_meals_ouline:before {
  content: "";
}

.material-icons.no_meeting_room:before {
  content: "";
}

.material-icons.no_photography:before {
  content: "";
}

.material-icons.no_sim:before {
  content: "";
}

.material-icons.no_stroller:before {
  content: "";
}

.material-icons.no_transfer:before {
  content: "";
}

.material-icons.noise_aware:before {
  content: "";
}

.material-icons.noise_control_off:before {
  content: "";
}

.material-icons.nordic_walking:before {
  content: "";
}

.material-icons.north:before {
  content: "";
}

.material-icons.north_east:before {
  content: "";
}

.material-icons.north_west:before {
  content: "";
}

.material-icons.not_accessible:before {
  content: "";
}

.material-icons.not_interested:before {
  content: "";
}

.material-icons.not_listed_location:before {
  content: "";
}

.material-icons.not_started:before {
  content: "";
}

.material-icons.note:before {
  content: "";
}

.material-icons.note_add:before {
  content: "";
}

.material-icons.note_alt:before {
  content: "";
}

.material-icons.notes:before {
  content: "";
}

.material-icons.notification_add:before {
  content: "";
}

.material-icons.notification_important:before {
  content: "";
}

.material-icons.notifications:before {
  content: "";
}

.material-icons.notifications_active:before {
  content: "";
}

.material-icons.notifications_none:before {
  content: "";
}

.material-icons.notifications_off:before {
  content: "";
}

.material-icons.notifications_on:before {
  content: "";
}

.material-icons.notifications_paused:before {
  content: "";
}

.material-icons.now_wallpaper:before {
  content: "";
}

.material-icons.now_widgets:before {
  content: "";
}

.material-icons.numbers:before {
  content: "";
}

.material-icons.offline_bolt:before {
  content: "";
}

.material-icons.offline_pin:before {
  content: "";
}

.material-icons.offline_share:before {
  content: "";
}

.material-icons.oil_barrel:before {
  content: "";
}

.material-icons.on_device_training:before {
  content: "";
}

.material-icons.ondemand_video:before {
  content: "";
}

.material-icons.online_prediction:before {
  content: "";
}

.material-icons.opacity:before {
  content: "";
}

.material-icons.open_in_browser:before {
  content: "";
}

.material-icons.open_in_full:before {
  content: "";
}

.material-icons.open_in_new:before {
  content: "";
}

.material-icons.open_in_new_off:before {
  content: "";
}

.material-icons.open_with:before {
  content: "";
}

.material-icons.other_houses:before {
  content: "";
}

.material-icons.outbond:before {
  content: "";
}

.material-icons.outbound:before {
  content: "";
}

.material-icons.outbox:before {
  content: "";
}

.material-icons.outdoor_grill:before {
  content: "";
}

.material-icons.outgoing_mail:before {
  content: "";
}

.material-icons.outlet:before {
  content: "";
}

.material-icons.outlined_flag:before {
  content: "";
}

.material-icons.output:before {
  content: "";
}

.material-icons.padding:before {
  content: "";
}

.material-icons.pages:before {
  content: "";
}

.material-icons.pageview:before {
  content: "";
}

.material-icons.paid:before {
  content: "";
}

.material-icons.palette:before {
  content: "";
}

.material-icons.pan_tool:before {
  content: "";
}

.material-icons.pan_tool_alt:before {
  content: "";
}

.material-icons.panorama:before {
  content: "";
}

.material-icons.panorama_fish_eye:before, .material-icons.panorama_fisheye:before {
  content: "";
}

.material-icons.panorama_horizontal:before {
  content: "";
}

.material-icons.panorama_horizontal_select:before {
  content: "";
}

.material-icons.panorama_photosphere:before {
  content: "";
}

.material-icons.panorama_photosphere_select:before {
  content: "";
}

.material-icons.panorama_vertical:before {
  content: "";
}

.material-icons.panorama_vertical_select:before {
  content: "";
}

.material-icons.panorama_wide_angle:before {
  content: "";
}

.material-icons.panorama_wide_angle_select:before {
  content: "";
}

.material-icons.paragliding:before {
  content: "";
}

.material-icons.park:before {
  content: "";
}

.material-icons.party_mode:before {
  content: "";
}

.material-icons.password:before {
  content: "";
}

.material-icons.pattern:before {
  content: "";
}

.material-icons.pause:before {
  content: "";
}

.material-icons.pause_circle:before {
  content: "";
}

.material-icons.pause_circle_filled:before {
  content: "";
}

.material-icons.pause_circle_outline:before {
  content: "";
}

.material-icons.pause_presentation:before {
  content: "";
}

.material-icons.payment:before {
  content: "";
}

.material-icons.payments:before {
  content: "";
}

.material-icons.paypal:before {
  content: "";
}

.material-icons.pedal_bike:before {
  content: "";
}

.material-icons.pending:before {
  content: "";
}

.material-icons.pending_actions:before {
  content: "";
}

.material-icons.pentagon:before {
  content: "";
}

.material-icons.people:before {
  content: "";
}

.material-icons.people_alt:before {
  content: "";
}

.material-icons.people_outline:before {
  content: "";
}

.material-icons.percent:before {
  content: "";
}

.material-icons.perm_camera_mic:before {
  content: "";
}

.material-icons.perm_contact_cal:before, .material-icons.perm_contact_calendar:before {
  content: "";
}

.material-icons.perm_data_setting:before {
  content: "";
}

.material-icons.perm_device_info:before, .material-icons.perm_device_information:before {
  content: "";
}

.material-icons.perm_identity:before {
  content: "";
}

.material-icons.perm_media:before {
  content: "";
}

.material-icons.perm_phone_msg:before {
  content: "";
}

.material-icons.perm_scan_wifi:before {
  content: "";
}

.material-icons.person:before {
  content: "";
}

.material-icons.person_add:before {
  content: "";
}

.material-icons.person_add_alt:before {
  content: "";
}

.material-icons.person_add_alt_1:before {
  content: "";
}

.material-icons.person_add_disabled:before {
  content: "";
}

.material-icons.person_off:before {
  content: "";
}

.material-icons.person_outline:before {
  content: "";
}

.material-icons.person_pin:before {
  content: "";
}

.material-icons.person_pin_circle:before {
  content: "";
}

.material-icons.person_remove:before {
  content: "";
}

.material-icons.person_remove_alt_1:before {
  content: "";
}

.material-icons.person_search:before {
  content: "";
}

.material-icons.personal_injury:before {
  content: "";
}

.material-icons.personal_video:before {
  content: "";
}

.material-icons.pest_control:before {
  content: "";
}

.material-icons.pest_control_rodent:before {
  content: "";
}

.material-icons.pets:before {
  content: "";
}

.material-icons.phishing:before {
  content: "";
}

.material-icons.phone:before {
  content: "";
}

.material-icons.phone_android:before {
  content: "";
}

.material-icons.phone_bluetooth_speaker:before {
  content: "";
}

.material-icons.phone_callback:before {
  content: "";
}

.material-icons.phone_disabled:before {
  content: "";
}

.material-icons.phone_enabled:before {
  content: "";
}

.material-icons.phone_forwarded:before {
  content: "";
}

.material-icons.phone_in_talk:before {
  content: "";
}

.material-icons.phone_iphone:before {
  content: "";
}

.material-icons.phone_locked:before {
  content: "";
}

.material-icons.phone_missed:before {
  content: "";
}

.material-icons.phone_paused:before {
  content: "";
}

.material-icons.phonelink:before {
  content: "";
}

.material-icons.phonelink_erase:before {
  content: "";
}

.material-icons.phonelink_lock:before {
  content: "";
}

.material-icons.phonelink_off:before {
  content: "";
}

.material-icons.phonelink_ring:before {
  content: "";
}

.material-icons.phonelink_setup:before {
  content: "";
}

.material-icons.photo:before {
  content: "";
}

.material-icons.photo_album:before {
  content: "";
}

.material-icons.photo_camera:before {
  content: "";
}

.material-icons.photo_camera_back:before {
  content: "";
}

.material-icons.photo_camera_front:before {
  content: "";
}

.material-icons.photo_filter:before {
  content: "";
}

.material-icons.photo_library:before {
  content: "";
}

.material-icons.photo_size_select_actual:before {
  content: "";
}

.material-icons.photo_size_select_large:before {
  content: "";
}

.material-icons.photo_size_select_small:before {
  content: "";
}

.material-icons.php:before {
  content: "";
}

.material-icons.piano:before {
  content: "";
}

.material-icons.piano_off:before {
  content: "";
}

.material-icons.picture_as_pdf:before {
  content: "";
}

.material-icons.picture_in_picture:before {
  content: "";
}

.material-icons.picture_in_picture_alt:before {
  content: "";
}

.material-icons.pie_chart:before {
  content: "";
}

.material-icons.pie_chart_outline:before {
  content: "";
}

.material-icons.pie_chart_outlined:before {
  content: "";
}

.material-icons.pin:before {
  content: "";
}

.material-icons.pin_drop:before {
  content: "";
}

.material-icons.pin_end:before {
  content: "";
}

.material-icons.pin_invoke:before {
  content: "";
}

.material-icons.pinch:before {
  content: "";
}

.material-icons.pivot_table_chart:before {
  content: "";
}

.material-icons.pix:before {
  content: "";
}

.material-icons.place:before {
  content: "";
}

.material-icons.plagiarism:before {
  content: "";
}

.material-icons.play_arrow:before {
  content: "";
}

.material-icons.play_circle:before {
  content: "";
}

.material-icons.play_circle_fill:before, .material-icons.play_circle_filled:before {
  content: "";
}

.material-icons.play_circle_outline:before {
  content: "";
}

.material-icons.play_disabled:before {
  content: "";
}

.material-icons.play_for_work:before {
  content: "";
}

.material-icons.play_lesson:before {
  content: "";
}

.material-icons.playlist_add:before {
  content: "";
}

.material-icons.playlist_add_check:before {
  content: "";
}

.material-icons.playlist_add_check_circle:before {
  content: "";
}

.material-icons.playlist_add_circle:before {
  content: "";
}

.material-icons.playlist_play:before {
  content: "";
}

.material-icons.playlist_remove:before {
  content: "";
}

.material-icons.plumbing:before {
  content: "";
}

.material-icons.plus_one:before {
  content: "";
}

.material-icons.podcasts:before {
  content: "";
}

.material-icons.point_of_sale:before {
  content: "";
}

.material-icons.policy:before {
  content: "";
}

.material-icons.poll:before {
  content: "";
}

.material-icons.polyline:before {
  content: "";
}

.material-icons.polymer:before {
  content: "";
}

.material-icons.pool:before {
  content: "";
}

.material-icons.portable_wifi_off:before {
  content: "";
}

.material-icons.portrait:before {
  content: "";
}

.material-icons.post_add:before {
  content: "";
}

.material-icons.power:before {
  content: "";
}

.material-icons.power_input:before {
  content: "";
}

.material-icons.power_off:before {
  content: "";
}

.material-icons.power_settings_new:before {
  content: "";
}

.material-icons.precision_manufacturing:before {
  content: "";
}

.material-icons.pregnant_woman:before {
  content: "";
}

.material-icons.present_to_all:before {
  content: "";
}

.material-icons.preview:before {
  content: "";
}

.material-icons.price_change:before {
  content: "";
}

.material-icons.price_check:before {
  content: "";
}

.material-icons.print:before {
  content: "";
}

.material-icons.print_disabled:before {
  content: "";
}

.material-icons.priority_high:before {
  content: "";
}

.material-icons.privacy_tip:before {
  content: "";
}

.material-icons.private_connectivity:before {
  content: "";
}

.material-icons.production_quantity_limits:before {
  content: "";
}

.material-icons.propane:before {
  content: "";
}

.material-icons.propane_tank:before {
  content: "";
}

.material-icons.psychology:before {
  content: "";
}

.material-icons.psychology_alt:before {
  content: "";
}

.material-icons.public:before {
  content: "";
}

.material-icons.public_off:before {
  content: "";
}

.material-icons.publish:before {
  content: "";
}

.material-icons.published_with_changes:before {
  content: "";
}

.material-icons.punch_clock:before {
  content: "";
}

.material-icons.push_pin:before {
  content: "";
}

.material-icons.qr_code:before {
  content: "";
}

.material-icons.qr_code_2:before {
  content: "";
}

.material-icons.qr_code_scanner:before {
  content: "";
}

.material-icons.query_builder:before {
  content: "";
}

.material-icons.query_stats:before {
  content: "";
}

.material-icons.question_answer:before {
  content: "";
}

.material-icons.question_mark:before {
  content: "";
}

.material-icons.queue:before {
  content: "";
}

.material-icons.queue_music:before {
  content: "";
}

.material-icons.queue_play_next:before {
  content: "";
}

.material-icons.quick_contacts_dialer:before {
  content: "";
}

.material-icons.quick_contacts_mail:before {
  content: "";
}

.material-icons.quickreply:before {
  content: "";
}

.material-icons.quiz:before {
  content: "";
}

.material-icons.quora:before {
  content: "";
}

.material-icons.r_mobiledata:before {
  content: "";
}

.material-icons.radar:before {
  content: "";
}

.material-icons.radio:before {
  content: "";
}

.material-icons.radio_button_checked:before {
  content: "";
}

.material-icons.radio_button_off:before {
  content: "";
}

.material-icons.radio_button_on:before {
  content: "";
}

.material-icons.radio_button_unchecked:before {
  content: "";
}

.material-icons.railway_alert:before {
  content: "";
}

.material-icons.ramen_dining:before {
  content: "";
}

.material-icons.ramp_left:before {
  content: "";
}

.material-icons.ramp_right:before {
  content: "";
}

.material-icons.rate_review:before {
  content: "";
}

.material-icons.raw_off:before {
  content: "";
}

.material-icons.raw_on:before {
  content: "";
}

.material-icons.read_more:before {
  content: "";
}

.material-icons.real_estate_agent:before {
  content: "";
}

.material-icons.receipt:before {
  content: "";
}

.material-icons.receipt_long:before {
  content: "";
}

.material-icons.recent_actors:before {
  content: "";
}

.material-icons.recommend:before {
  content: "";
}

.material-icons.record_voice_over:before {
  content: "";
}

.material-icons.rectangle:before {
  content: "";
}

.material-icons.recycling:before {
  content: "";
}

.material-icons.reddit:before {
  content: "";
}

.material-icons.redeem:before {
  content: "";
}

.material-icons.redo:before {
  content: "";
}

.material-icons.reduce_capacity:before {
  content: "";
}

.material-icons.refresh:before {
  content: "";
}

.material-icons.remember_me:before {
  content: "";
}

.material-icons.remove:before {
  content: "";
}

.material-icons.remove_circle:before {
  content: "";
}

.material-icons.remove_circle_outline:before {
  content: "";
}

.material-icons.remove_done:before {
  content: "";
}

.material-icons.remove_from_queue:before {
  content: "";
}

.material-icons.remove_moderator:before {
  content: "";
}

.material-icons.remove_red_eye:before {
  content: "";
}

.material-icons.remove_road:before {
  content: "";
}

.material-icons.remove_shopping_cart:before {
  content: "";
}

.material-icons.reorder:before {
  content: "";
}

.material-icons.repartition:before {
  content: "";
}

.material-icons.repeat:before {
  content: "";
}

.material-icons.repeat_on:before {
  content: "";
}

.material-icons.repeat_one:before {
  content: "";
}

.material-icons.repeat_one_on:before {
  content: "";
}

.material-icons.replay:before {
  content: "";
}

.material-icons.replay_10:before {
  content: "";
}

.material-icons.replay_30:before {
  content: "";
}

.material-icons.replay_5:before {
  content: "";
}

.material-icons.replay_circle_filled:before {
  content: "";
}

.material-icons.reply:before {
  content: "";
}

.material-icons.reply_all:before {
  content: "";
}

.material-icons.report:before {
  content: "";
}

.material-icons.report_gmailerrorred:before {
  content: "";
}

.material-icons.report_off:before {
  content: "";
}

.material-icons.report_problem:before {
  content: "";
}

.material-icons.request_page:before {
  content: "";
}

.material-icons.request_quote:before {
  content: "";
}

.material-icons.reset_tv:before {
  content: "";
}

.material-icons.restart_alt:before {
  content: "";
}

.material-icons.restaurant:before {
  content: "";
}

.material-icons.restaurant_menu:before {
  content: "";
}

.material-icons.restore:before {
  content: "";
}

.material-icons.restore_from_trash:before {
  content: "";
}

.material-icons.restore_page:before {
  content: "";
}

.material-icons.reviews:before {
  content: "";
}

.material-icons.rice_bowl:before {
  content: "";
}

.material-icons.ring_volume:before {
  content: "";
}

.material-icons.rocket:before {
  content: "";
}

.material-icons.rocket_launch:before {
  content: "";
}

.material-icons.roller_shades:before {
  content: "";
}

.material-icons.roller_shades_closed:before {
  content: "";
}

.material-icons.roller_skating:before {
  content: "";
}

.material-icons.roofing:before {
  content: "";
}

.material-icons.room:before {
  content: "";
}

.material-icons.room_preferences:before {
  content: "";
}

.material-icons.room_service:before {
  content: "";
}

.material-icons.rotate_90_degrees_ccw:before {
  content: "";
}

.material-icons.rotate_90_degrees_cw:before {
  content: "";
}

.material-icons.rotate_left:before {
  content: "";
}

.material-icons.rotate_right:before {
  content: "";
}

.material-icons.roundabout_left:before {
  content: "";
}

.material-icons.roundabout_right:before {
  content: "";
}

.material-icons.rounded_corner:before {
  content: "";
}

.material-icons.route:before {
  content: "";
}

.material-icons.router:before {
  content: "";
}

.material-icons.rowing:before {
  content: "";
}

.material-icons.rss_feed:before {
  content: "";
}

.material-icons.rsvp:before {
  content: "";
}

.material-icons.rtt:before {
  content: "";
}

.material-icons.rule:before {
  content: "";
}

.material-icons.rule_folder:before {
  content: "";
}

.material-icons.run_circle:before {
  content: "";
}

.material-icons.running_with_errors:before {
  content: "";
}

.material-icons.rv_hookup:before {
  content: "";
}

.material-icons.safety_check:before {
  content: "";
}

.material-icons.safety_divider:before {
  content: "";
}

.material-icons.sailing:before {
  content: "";
}

.material-icons.sanitizer:before {
  content: "";
}

.material-icons.satellite:before {
  content: "";
}

.material-icons.satellite_alt:before {
  content: "";
}

.material-icons.save:before {
  content: "";
}

.material-icons.save_alt:before {
  content: "";
}

.material-icons.save_as:before {
  content: "";
}

.material-icons.saved_search:before {
  content: "";
}

.material-icons.savings:before {
  content: "";
}

.material-icons.scale:before {
  content: "";
}

.material-icons.scanner:before {
  content: "";
}

.material-icons.scatter_plot:before {
  content: "";
}

.material-icons.schedule:before {
  content: "";
}

.material-icons.schedule_send:before {
  content: "";
}

.material-icons.schema:before {
  content: "";
}

.material-icons.school:before {
  content: "";
}

.material-icons.science:before {
  content: "";
}

.material-icons.score:before {
  content: "";
}

.material-icons.scoreboard:before {
  content: "";
}

.material-icons.screen_lock_landscape:before {
  content: "";
}

.material-icons.screen_lock_portrait:before {
  content: "";
}

.material-icons.screen_lock_rotation:before {
  content: "";
}

.material-icons.screen_rotation:before {
  content: "";
}

.material-icons.screen_rotation_alt:before {
  content: "";
}

.material-icons.screen_search_desktop:before {
  content: "";
}

.material-icons.screen_share:before {
  content: "";
}

.material-icons.screenshot:before {
  content: "";
}

.material-icons.screenshot_monitor:before {
  content: "";
}

.material-icons.scuba_diving:before {
  content: "";
}

.material-icons.sd:before {
  content: "";
}

.material-icons.sd_card:before {
  content: "";
}

.material-icons.sd_card_alert:before {
  content: "";
}

.material-icons.sd_storage:before {
  content: "";
}

.material-icons.search:before {
  content: "";
}

.material-icons.search_off:before {
  content: "";
}

.material-icons.security:before {
  content: "";
}

.material-icons.security_update:before {
  content: "";
}

.material-icons.security_update_good:before {
  content: "";
}

.material-icons.security_update_warning:before {
  content: "";
}

.material-icons.segment:before {
  content: "";
}

.material-icons.select_all:before {
  content: "";
}

.material-icons.self_improvement:before {
  content: "";
}

.material-icons.sell:before {
  content: "";
}

.material-icons.send:before {
  content: "";
}

.material-icons.send_and_archive:before {
  content: "";
}

.material-icons.send_time_extension:before {
  content: "";
}

.material-icons.send_to_mobile:before {
  content: "";
}

.material-icons.sensor_door:before {
  content: "";
}

.material-icons.sensor_occupied:before {
  content: "";
}

.material-icons.sensor_window:before {
  content: "";
}

.material-icons.sensors:before {
  content: "";
}

.material-icons.sensors_off:before {
  content: "";
}

.material-icons.sentiment_dissatisfied:before {
  content: "";
}

.material-icons.sentiment_neutral:before {
  content: "";
}

.material-icons.sentiment_satisfied:before {
  content: "";
}

.material-icons.sentiment_satisfied_alt:before {
  content: "";
}

.material-icons.sentiment_very_dissatisfied:before {
  content: "";
}

.material-icons.sentiment_very_satisfied:before {
  content: "";
}

.material-icons.set_meal:before {
  content: "";
}

.material-icons.settings:before {
  content: "";
}

.material-icons.settings_accessibility:before {
  content: "";
}

.material-icons.settings_applications:before {
  content: "";
}

.material-icons.settings_backup_restore:before {
  content: "";
}

.material-icons.settings_bluetooth:before {
  content: "";
}

.material-icons.settings_brightness:before {
  content: "";
}

.material-icons.settings_cell:before {
  content: "";
}

.material-icons.settings_display:before {
  content: "";
}

.material-icons.settings_ethernet:before {
  content: "";
}

.material-icons.settings_input_antenna:before {
  content: "";
}

.material-icons.settings_input_component:before {
  content: "";
}

.material-icons.settings_input_composite:before {
  content: "";
}

.material-icons.settings_input_hdmi:before {
  content: "";
}

.material-icons.settings_input_svideo:before {
  content: "";
}

.material-icons.settings_overscan:before {
  content: "";
}

.material-icons.settings_phone:before {
  content: "";
}

.material-icons.settings_power:before {
  content: "";
}

.material-icons.settings_remote:before {
  content: "";
}

.material-icons.settings_suggest:before {
  content: "";
}

.material-icons.settings_system_daydream:before {
  content: "";
}

.material-icons.settings_voice:before {
  content: "";
}

.material-icons.severe_cold:before {
  content: "";
}

.material-icons.share:before {
  content: "";
}

.material-icons.share_arrival_time:before {
  content: "";
}

.material-icons.share_location:before {
  content: "";
}

.material-icons.shield:before {
  content: "";
}

.material-icons.shield_moon:before {
  content: "";
}

.material-icons.shop:before {
  content: "";
}

.material-icons.shop_2:before {
  content: "";
}

.material-icons.shop_two:before {
  content: "";
}

.material-icons.shopify:before {
  content: "";
}

.material-icons.shopping_bag:before {
  content: "";
}

.material-icons.shopping_basket:before {
  content: "";
}

.material-icons.shopping_cart:before {
  content: "";
}

.material-icons.shopping_cart_checkout:before {
  content: "";
}

.material-icons.short_text:before {
  content: "";
}

.material-icons.shortcut:before {
  content: "";
}

.material-icons.show_chart:before {
  content: "";
}

.material-icons.shower:before {
  content: "";
}

.material-icons.shuffle:before {
  content: "";
}

.material-icons.shuffle_on:before {
  content: "";
}

.material-icons.shutter_speed:before {
  content: "";
}

.material-icons.sick:before {
  content: "";
}

.material-icons.sign_language:before {
  content: "";
}

.material-icons.signal_cellular_0_bar:before {
  content: "";
}

.material-icons.signal_cellular_4_bar:before {
  content: "";
}

.material-icons.signal_cellular_alt:before {
  content: "";
}

.material-icons.signal_cellular_alt_1_bar:before {
  content: "";
}

.material-icons.signal_cellular_alt_2_bar:before {
  content: "";
}

.material-icons.signal_cellular_connected_no_internet_0_bar:before {
  content: "";
}

.material-icons.signal_cellular_connected_no_internet_4_bar:before {
  content: "";
}

.material-icons.signal_cellular_no_sim:before {
  content: "";
}

.material-icons.signal_cellular_nodata:before {
  content: "";
}

.material-icons.signal_cellular_null:before {
  content: "";
}

.material-icons.signal_cellular_off:before {
  content: "";
}

.material-icons.signal_wifi_0_bar:before {
  content: "";
}

.material-icons.signal_wifi_4_bar:before {
  content: "";
}

.material-icons.signal_wifi_4_bar_lock:before {
  content: "";
}

.material-icons.signal_wifi_bad:before {
  content: "";
}

.material-icons.signal_wifi_connected_no_internet_4:before {
  content: "";
}

.material-icons.signal_wifi_off:before {
  content: "";
}

.material-icons.signal_wifi_statusbar_4_bar:before {
  content: "";
}

.material-icons.signal_wifi_statusbar_connected_no_internet_4:before {
  content: "";
}

.material-icons.signal_wifi_statusbar_null:before {
  content: "";
}

.material-icons.signpost:before {
  content: "";
}

.material-icons.sim_card:before {
  content: "";
}

.material-icons.sim_card_alert:before {
  content: "";
}

.material-icons.sim_card_download:before {
  content: "";
}

.material-icons.single_bed:before {
  content: "";
}

.material-icons.sip:before {
  content: "";
}

.material-icons.skateboarding:before {
  content: "";
}

.material-icons.skip_next:before {
  content: "";
}

.material-icons.skip_previous:before {
  content: "";
}

.material-icons.sledding:before {
  content: "";
}

.material-icons.slideshow:before {
  content: "";
}

.material-icons.slow_motion_video:before {
  content: "";
}

.material-icons.smart_button:before {
  content: "";
}

.material-icons.smart_display:before {
  content: "";
}

.material-icons.smart_screen:before {
  content: "";
}

.material-icons.smart_toy:before {
  content: "";
}

.material-icons.smartphone:before {
  content: "";
}

.material-icons.smoke_free:before {
  content: "";
}

.material-icons.smoking_rooms:before {
  content: "";
}

.material-icons.sms:before {
  content: "";
}

.material-icons.sms_failed:before {
  content: "";
}

.material-icons.snapchat:before {
  content: "";
}

.material-icons.snippet_folder:before {
  content: "";
}

.material-icons.snooze:before {
  content: "";
}

.material-icons.snowboarding:before {
  content: "";
}

.material-icons.snowing:before {
  content: "";
}

.material-icons.snowmobile:before {
  content: "";
}

.material-icons.snowshoeing:before {
  content: "";
}

.material-icons.soap:before {
  content: "";
}

.material-icons.social_distance:before {
  content: "";
}

.material-icons.solar_power:before {
  content: "";
}

.material-icons.sort:before {
  content: "";
}

.material-icons.sort_by_alpha:before {
  content: "";
}

.material-icons.sos:before {
  content: "";
}

.material-icons.soup_kitchen:before {
  content: "";
}

.material-icons.source:before {
  content: "";
}

.material-icons.south:before {
  content: "";
}

.material-icons.south_america:before {
  content: "";
}

.material-icons.south_east:before {
  content: "";
}

.material-icons.south_west:before {
  content: "";
}

.material-icons.spa:before {
  content: "";
}

.material-icons.space_bar:before {
  content: "";
}

.material-icons.space_dashboard:before {
  content: "";
}

.material-icons.spatial_audio:before {
  content: "";
}

.material-icons.spatial_audio_off:before {
  content: "";
}

.material-icons.spatial_tracking:before {
  content: "";
}

.material-icons.speaker:before {
  content: "";
}

.material-icons.speaker_group:before {
  content: "";
}

.material-icons.speaker_notes:before {
  content: "";
}

.material-icons.speaker_notes_off:before {
  content: "";
}

.material-icons.speaker_phone:before {
  content: "";
}

.material-icons.speed:before {
  content: "";
}

.material-icons.spellcheck:before {
  content: "";
}

.material-icons.splitscreen:before {
  content: "";
}

.material-icons.spoke:before {
  content: "";
}

.material-icons.sports:before {
  content: "";
}

.material-icons.sports_bar:before {
  content: "";
}

.material-icons.sports_baseball:before {
  content: "";
}

.material-icons.sports_basketball:before {
  content: "";
}

.material-icons.sports_cricket:before {
  content: "";
}

.material-icons.sports_esports:before {
  content: "";
}

.material-icons.sports_football:before {
  content: "";
}

.material-icons.sports_golf:before {
  content: "";
}

.material-icons.sports_gymnastics:before {
  content: "";
}

.material-icons.sports_handball:before {
  content: "";
}

.material-icons.sports_hockey:before {
  content: "";
}

.material-icons.sports_kabaddi:before {
  content: "";
}

.material-icons.sports_martial_arts:before {
  content: "";
}

.material-icons.sports_mma:before {
  content: "";
}

.material-icons.sports_motorsports:before {
  content: "";
}

.material-icons.sports_rugby:before {
  content: "";
}

.material-icons.sports_score:before {
  content: "";
}

.material-icons.sports_soccer:before {
  content: "";
}

.material-icons.sports_tennis:before {
  content: "";
}

.material-icons.sports_volleyball:before {
  content: "";
}

.material-icons.square:before {
  content: "";
}

.material-icons.square_foot:before {
  content: "";
}

.material-icons.ssid_chart:before {
  content: "";
}

.material-icons.stacked_bar_chart:before {
  content: "";
}

.material-icons.stacked_line_chart:before {
  content: "";
}

.material-icons.stadium:before {
  content: "";
}

.material-icons.stairs:before {
  content: "";
}

.material-icons.star:before {
  content: "";
}

.material-icons.star_border:before {
  content: "";
}

.material-icons.star_border_purple500:before {
  content: "";
}

.material-icons.star_half:before {
  content: "";
}

.material-icons.star_outline:before {
  content: "";
}

.material-icons.star_purple500:before {
  content: "";
}

.material-icons.star_rate:before {
  content: "";
}

.material-icons.stars:before {
  content: "";
}

.material-icons.start:before {
  content: "";
}

.material-icons.stay_current_landscape:before {
  content: "";
}

.material-icons.stay_current_portrait:before {
  content: "";
}

.material-icons.stay_primary_landscape:before {
  content: "";
}

.material-icons.stay_primary_portrait:before {
  content: "";
}

.material-icons.sticky_note_2:before {
  content: "";
}

.material-icons.stop:before {
  content: "";
}

.material-icons.stop_circle:before {
  content: "";
}

.material-icons.stop_screen_share:before {
  content: "";
}

.material-icons.storage:before {
  content: "";
}

.material-icons.store:before {
  content: "";
}

.material-icons.store_mall_directory:before {
  content: "";
}

.material-icons.storefront:before {
  content: "";
}

.material-icons.storm:before {
  content: "";
}

.material-icons.straight:before {
  content: "";
}

.material-icons.straighten:before {
  content: "";
}

.material-icons.stream:before {
  content: "";
}

.material-icons.streetview:before {
  content: "";
}

.material-icons.strikethrough_s:before {
  content: "";
}

.material-icons.stroller:before {
  content: "";
}

.material-icons.style:before {
  content: "";
}

.material-icons.subdirectory_arrow_left:before {
  content: "";
}

.material-icons.subdirectory_arrow_right:before {
  content: "";
}

.material-icons.subject:before {
  content: "";
}

.material-icons.subscript:before {
  content: "";
}

.material-icons.subscriptions:before {
  content: "";
}

.material-icons.subtitles:before {
  content: "";
}

.material-icons.subtitles_off:before {
  content: "";
}

.material-icons.subway:before {
  content: "";
}

.material-icons.summarize:before {
  content: "";
}

.material-icons.sunny:before {
  content: "";
}

.material-icons.sunny_snowing:before {
  content: "";
}

.material-icons.superscript:before {
  content: "";
}

.material-icons.supervised_user_circle:before {
  content: "";
}

.material-icons.supervisor_account:before {
  content: "";
}

.material-icons.support:before {
  content: "";
}

.material-icons.support_agent:before {
  content: "";
}

.material-icons.surfing:before {
  content: "";
}

.material-icons.surround_sound:before {
  content: "";
}

.material-icons.swap_calls:before {
  content: "";
}

.material-icons.swap_horiz:before {
  content: "";
}

.material-icons.swap_horizontal_circle:before {
  content: "";
}

.material-icons.swap_vert:before {
  content: "";
}

.material-icons.swap_vert_circle:before, .material-icons.swap_vertical_circle:before {
  content: "";
}

.material-icons.swipe:before {
  content: "";
}

.material-icons.swipe_down:before {
  content: "";
}

.material-icons.swipe_down_alt:before {
  content: "";
}

.material-icons.swipe_left:before {
  content: "";
}

.material-icons.swipe_left_alt:before {
  content: "";
}

.material-icons.swipe_right:before {
  content: "";
}

.material-icons.swipe_right_alt:before {
  content: "";
}

.material-icons.swipe_up:before {
  content: "";
}

.material-icons.swipe_up_alt:before {
  content: "";
}

.material-icons.swipe_vertical:before {
  content: "";
}

.material-icons.switch_access_shortcut:before {
  content: "";
}

.material-icons.switch_access_shortcut_add:before {
  content: "";
}

.material-icons.switch_account:before {
  content: "";
}

.material-icons.switch_camera:before {
  content: "";
}

.material-icons.switch_left:before {
  content: "";
}

.material-icons.switch_right:before {
  content: "";
}

.material-icons.switch_video:before {
  content: "";
}

.material-icons.synagogue:before {
  content: "";
}

.material-icons.sync:before {
  content: "";
}

.material-icons.sync_alt:before {
  content: "";
}

.material-icons.sync_disabled:before {
  content: "";
}

.material-icons.sync_lock:before {
  content: "";
}

.material-icons.sync_problem:before {
  content: "";
}

.material-icons.system_security_update:before {
  content: "";
}

.material-icons.system_security_update_good:before {
  content: "";
}

.material-icons.system_security_update_warning:before {
  content: "";
}

.material-icons.system_update:before {
  content: "";
}

.material-icons.system_update_alt:before, .material-icons.system_update_tv:before {
  content: "";
}

.material-icons.tab:before {
  content: "";
}

.material-icons.tab_unselected:before {
  content: "";
}

.material-icons.table_bar:before {
  content: "";
}

.material-icons.table_chart:before {
  content: "";
}

.material-icons.table_restaurant:before {
  content: "";
}

.material-icons.table_rows:before {
  content: "";
}

.material-icons.table_view:before {
  content: "";
}

.material-icons.tablet:before {
  content: "";
}

.material-icons.tablet_android:before {
  content: "";
}

.material-icons.tablet_mac:before {
  content: "";
}

.material-icons.tag:before {
  content: "";
}

.material-icons.tag_faces:before {
  content: "";
}

.material-icons.takeout_dining:before {
  content: "";
}

.material-icons.tap_and_play:before {
  content: "";
}

.material-icons.tapas:before {
  content: "";
}

.material-icons.task:before {
  content: "";
}

.material-icons.task_alt:before {
  content: "";
}

.material-icons.taxi_alert:before {
  content: "";
}

.material-icons.telegram:before {
  content: "";
}

.material-icons.temple_buddhist:before {
  content: "";
}

.material-icons.temple_hindu:before {
  content: "";
}

.material-icons.terminal:before {
  content: "";
}

.material-icons.terrain:before {
  content: "";
}

.material-icons.text_decrease:before {
  content: "";
}

.material-icons.text_fields:before {
  content: "";
}

.material-icons.text_format:before {
  content: "";
}

.material-icons.text_increase:before {
  content: "";
}

.material-icons.text_rotate_up:before {
  content: "";
}

.material-icons.text_rotate_vertical:before {
  content: "";
}

.material-icons.text_rotation_angledown:before {
  content: "";
}

.material-icons.text_rotation_angleup:before {
  content: "";
}

.material-icons.text_rotation_down:before {
  content: "";
}

.material-icons.text_rotation_none:before {
  content: "";
}

.material-icons.text_snippet:before {
  content: "";
}

.material-icons.textsms:before {
  content: "";
}

.material-icons.texture:before {
  content: "";
}

.material-icons.theater_comedy:before {
  content: "";
}

.material-icons.theaters:before {
  content: "";
}

.material-icons.thermostat:before {
  content: "";
}

.material-icons.thermostat_auto:before {
  content: "";
}

.material-icons.thumb_down:before {
  content: "";
}

.material-icons.thumb_down_alt:before {
  content: "";
}

.material-icons.thumb_down_off_alt:before {
  content: "";
}

.material-icons.thumb_up:before {
  content: "";
}

.material-icons.thumb_up_alt:before {
  content: "";
}

.material-icons.thumb_up_off_alt:before {
  content: "";
}

.material-icons.thumbs_up_down:before {
  content: "";
}

.material-icons.thunderstorm:before {
  content: "";
}

.material-icons.tiktok:before {
  content: "";
}

.material-icons.time_to_leave:before {
  content: "";
}

.material-icons.timelapse:before {
  content: "";
}

.material-icons.timeline:before {
  content: "";
}

.material-icons.timer:before {
  content: "";
}

.material-icons.timer_10:before {
  content: "";
}

.material-icons.timer_10_select:before {
  content: "";
}

.material-icons.timer_3:before {
  content: "";
}

.material-icons.timer_3_select:before {
  content: "";
}

.material-icons.timer_off:before {
  content: "";
}

.material-icons.tips_and_updates:before {
  content: "";
}

.material-icons.tire_repair:before {
  content: "";
}

.material-icons.title:before {
  content: "";
}

.material-icons.toc:before {
  content: "";
}

.material-icons.today:before {
  content: "";
}

.material-icons.toggle_off:before {
  content: "";
}

.material-icons.toggle_on:before {
  content: "";
}

.material-icons.token:before {
  content: "";
}

.material-icons.toll:before {
  content: "";
}

.material-icons.tonality:before {
  content: "";
}

.material-icons.topic:before {
  content: "";
}

.material-icons.tornado:before {
  content: "";
}

.material-icons.touch_app:before {
  content: "";
}

.material-icons.tour:before {
  content: "";
}

.material-icons.toys:before {
  content: "";
}

.material-icons.track_changes:before {
  content: "";
}

.material-icons.traffic:before {
  content: "";
}

.material-icons.train:before {
  content: "";
}

.material-icons.tram:before {
  content: "";
}

.material-icons.transcribe:before {
  content: "";
}

.material-icons.transfer_within_a_station:before {
  content: "";
}

.material-icons.transform:before {
  content: "";
}

.material-icons.transgender:before {
  content: "";
}

.material-icons.transit_enterexit:before {
  content: "";
}

.material-icons.translate:before {
  content: "";
}

.material-icons.travel_explore:before {
  content: "";
}

.material-icons.trending_down:before {
  content: "";
}

.material-icons.trending_flat:before, .material-icons.trending_neutral:before {
  content: "";
}

.material-icons.trending_up:before {
  content: "";
}

.material-icons.trip_origin:before {
  content: "";
}

.material-icons.troubleshoot:before {
  content: "";
}

.material-icons.try:before {
  content: "";
}

.material-icons.tsunami:before {
  content: "";
}

.material-icons.tty:before {
  content: "";
}

.material-icons.tune:before {
  content: "";
}

.material-icons.tungsten:before {
  content: "";
}

.material-icons.turn_left:before {
  content: "";
}

.material-icons.turn_right:before {
  content: "";
}

.material-icons.turn_sharp_left:before {
  content: "";
}

.material-icons.turn_sharp_right:before {
  content: "";
}

.material-icons.turn_slight_left:before {
  content: "";
}

.material-icons.turn_slight_right:before {
  content: "";
}

.material-icons.turned_in:before {
  content: "";
}

.material-icons.turned_in_not:before {
  content: "";
}

.material-icons.tv:before {
  content: "";
}

.material-icons.tv_off:before {
  content: "";
}

.material-icons.two_wheeler:before {
  content: "";
}

.material-icons.type_specimen:before {
  content: "";
}

.material-icons.u_turn_left:before {
  content: "";
}

.material-icons.u_turn_right:before {
  content: "";
}

.material-icons.umbrella:before {
  content: "";
}

.material-icons.unarchive:before {
  content: "";
}

.material-icons.undo:before {
  content: "";
}

.material-icons.unfold_less:before {
  content: "";
}

.material-icons.unfold_more:before {
  content: "";
}

.material-icons.unpublished:before {
  content: "";
}

.material-icons.unsubscribe:before {
  content: "";
}

.material-icons.upcoming:before {
  content: "";
}

.material-icons.update:before {
  content: "";
}

.material-icons.update_disabled:before {
  content: "";
}

.material-icons.upgrade:before {
  content: "";
}

.material-icons.upload:before {
  content: "";
}

.material-icons.upload_file:before {
  content: "";
}

.material-icons.usb:before {
  content: "";
}

.material-icons.usb_off:before {
  content: "";
}

.material-icons.vaccines:before {
  content: "";
}

.material-icons.vape_free:before {
  content: "";
}

.material-icons.vaping_rooms:before {
  content: "";
}

.material-icons.verified:before {
  content: "";
}

.material-icons.verified_user:before {
  content: "";
}

.material-icons.vertical_align_bottom:before {
  content: "";
}

.material-icons.vertical_align_center:before {
  content: "";
}

.material-icons.vertical_align_top:before {
  content: "";
}

.material-icons.vertical_distribute:before {
  content: "";
}

.material-icons.vertical_shades:before {
  content: "";
}

.material-icons.vertical_shades_closed:before {
  content: "";
}

.material-icons.vertical_split:before {
  content: "";
}

.material-icons.vibration:before {
  content: "";
}

.material-icons.video_call:before {
  content: "";
}

.material-icons.video_camera_back:before {
  content: "";
}

.material-icons.video_camera_front:before {
  content: "";
}

.material-icons.video_collection:before {
  content: "";
}

.material-icons.video_file:before {
  content: "";
}

.material-icons.video_label:before {
  content: "";
}

.material-icons.video_library:before {
  content: "";
}

.material-icons.video_settings:before {
  content: "";
}

.material-icons.video_stable:before {
  content: "";
}

.material-icons.videocam:before {
  content: "";
}

.material-icons.videocam_off:before {
  content: "";
}

.material-icons.videogame_asset:before {
  content: "";
}

.material-icons.videogame_asset_off:before {
  content: "";
}

.material-icons.view_agenda:before {
  content: "";
}

.material-icons.view_array:before {
  content: "";
}

.material-icons.view_carousel:before {
  content: "";
}

.material-icons.view_column:before {
  content: "";
}

.material-icons.view_comfortable:before, .material-icons.view_comfy:before {
  content: "";
}

.material-icons.view_comfy_alt:before {
  content: "";
}

.material-icons.view_compact:before {
  content: "";
}

.material-icons.view_compact_alt:before {
  content: "";
}

.material-icons.view_cozy:before {
  content: "";
}

.material-icons.view_day:before {
  content: "";
}

.material-icons.view_headline:before {
  content: "";
}

.material-icons.view_in_ar:before {
  content: "";
}

.material-icons.view_kanban:before {
  content: "";
}

.material-icons.view_list:before {
  content: "";
}

.material-icons.view_module:before {
  content: "";
}

.material-icons.view_quilt:before {
  content: "";
}

.material-icons.view_sidebar:before {
  content: "";
}

.material-icons.view_stream:before {
  content: "";
}

.material-icons.view_timeline:before {
  content: "";
}

.material-icons.view_week:before {
  content: "";
}

.material-icons.vignette:before {
  content: "";
}

.material-icons.villa:before {
  content: "";
}

.material-icons.visibility:before {
  content: "";
}

.material-icons.visibility_off:before {
  content: "";
}

.material-icons.voice_chat:before {
  content: "";
}

.material-icons.voice_over_off:before {
  content: "";
}

.material-icons.voicemail:before {
  content: "";
}

.material-icons.volcano:before {
  content: "";
}

.material-icons.volume_down:before {
  content: "";
}

.material-icons.volume_down_alt:before {
  content: "";
}

.material-icons.volume_mute:before {
  content: "";
}

.material-icons.volume_off:before {
  content: "";
}

.material-icons.volume_up:before {
  content: "";
}

.material-icons.volunteer_activism:before {
  content: "";
}

.material-icons.vpn_key:before {
  content: "";
}

.material-icons.vpn_key_off:before {
  content: "";
}

.material-icons.vpn_lock:before {
  content: "";
}

.material-icons.vrpano:before {
  content: "";
}

.material-icons.wallet:before {
  content: "";
}

.material-icons.wallet_giftcard:before {
  content: "";
}

.material-icons.wallet_membership:before {
  content: "";
}

.material-icons.wallet_travel:before {
  content: "";
}

.material-icons.wallpaper:before {
  content: "";
}

.material-icons.warehouse:before {
  content: "";
}

.material-icons.warning:before {
  content: "";
}

.material-icons.warning_amber:before {
  content: "";
}

.material-icons.wash:before {
  content: "";
}

.material-icons.watch:before {
  content: "";
}

.material-icons.watch_later:before {
  content: "";
}

.material-icons.watch_off:before {
  content: "";
}

.material-icons.water:before {
  content: "";
}

.material-icons.water_damage:before {
  content: "";
}

.material-icons.water_drop:before {
  content: "";
}

.material-icons.waterfall_chart:before {
  content: "";
}

.material-icons.waves:before {
  content: "";
}

.material-icons.waving_hand:before {
  content: "";
}

.material-icons.wb_auto:before {
  content: "";
}

.material-icons.wb_cloudy:before {
  content: "";
}

.material-icons.wb_incandescent:before {
  content: "";
}

.material-icons.wb_iridescent:before {
  content: "";
}

.material-icons.wb_shade:before {
  content: "";
}

.material-icons.wb_sunny:before {
  content: "";
}

.material-icons.wb_twighlight:before {
  content: "";
}

.material-icons.wb_twilight:before {
  content: "";
}

.material-icons.wc:before {
  content: "";
}

.material-icons.web:before {
  content: "";
}

.material-icons.web_asset:before {
  content: "";
}

.material-icons.web_asset_off:before {
  content: "";
}

.material-icons.web_stories:before {
  content: "";
}

.material-icons.webhook:before {
  content: "";
}

.material-icons.wechat:before {
  content: "";
}

.material-icons.weekend:before {
  content: "";
}

.material-icons.west:before {
  content: "";
}

.material-icons.whatsapp:before {
  content: "";
}

.material-icons.whatshot:before {
  content: "";
}

.material-icons.wheelchair_pickup:before {
  content: "";
}

.material-icons.where_to_vote:before {
  content: "";
}

.material-icons.widgets:before {
  content: "";
}

.material-icons.width_full:before {
  content: "";
}

.material-icons.width_normal:before {
  content: "";
}

.material-icons.width_wide:before {
  content: "";
}

.material-icons.wifi:before {
  content: "";
}

.material-icons.wifi_1_bar:before {
  content: "";
}

.material-icons.wifi_2_bar:before {
  content: "";
}

.material-icons.wifi_calling:before {
  content: "";
}

.material-icons.wifi_calling_3:before {
  content: "";
}

.material-icons.wifi_channel:before {
  content: "";
}

.material-icons.wifi_find:before {
  content: "";
}

.material-icons.wifi_lock:before {
  content: "";
}

.material-icons.wifi_off:before {
  content: "";
}

.material-icons.wifi_password:before {
  content: "";
}

.material-icons.wifi_protected_setup:before {
  content: "";
}

.material-icons.wifi_tethering:before {
  content: "";
}

.material-icons.wifi_tethering_error:before {
  content: "";
}

.material-icons.wifi_tethering_error_rounded:before {
  content: "";
}

.material-icons.wifi_tethering_off:before {
  content: "";
}

.material-icons.wind_power:before {
  content: "";
}

.material-icons.window:before {
  content: "";
}

.material-icons.wine_bar:before {
  content: "";
}

.material-icons.woman:before {
  content: "";
}

.material-icons.woo_commerce:before {
  content: "";
}

.material-icons.wordpress:before {
  content: "";
}

.material-icons.work:before {
  content: "";
}

.material-icons.work_history:before {
  content: "";
}

.material-icons.work_off:before {
  content: "";
}

.material-icons.work_outline:before {
  content: "";
}

.material-icons.workspace_premium:before {
  content: "";
}

.material-icons.workspaces:before {
  content: "";
}

.material-icons.workspaces_filled:before {
  content: "";
}

.material-icons.workspaces_outline:before {
  content: "";
}

.material-icons.wrap_text:before {
  content: "";
}

.material-icons.wrong_location:before {
  content: "";
}

.material-icons.wysiwyg:before {
  content: "";
}

.material-icons.yard:before {
  content: "";
}

.material-icons.youtube_searched_for:before {
  content: "";
}

.material-icons.zoom_in:before {
  content: "";
}

.material-icons.zoom_in_map:before {
  content: "";
}

.material-icons.zoom_out:before {
  content: "";
}

.material-icons.zoom_out_map:before {
  content: "";
}

.renderVideo {
  border: .5em var(--purp1) solid;
  border-radius: .5em;
  height: 40vh;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.flexwrap {
  flex-flow: column wrap;
  padding: 1em;
  display: flex;
}

.rendTableHead {
  background-color: var(--purp1);
}

.rendTableHead > th {
  color: var(--grey7);
}

.rendTableHead:first-of-type {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.rendTable > tr:first-child > td:first-of-type {
  border-top-left-radius: 0;
}

.rendTable > tr:first-child > td:last-of-type {
  border-top-right-radius: 0;
}

.rendTable > tr:last-child > td:first-of-type {
  border-bottom-left-radius: 0;
}

.rendTable > tr:last-child > td:last-of-type {
  border-bottom-right-radius: 0;
}

header {
  background: var(--grey);
  width: 100%;
  font-weight: bold;
  display: flex;
}

.logo {
  width: 180px;
  padding: 9px 15px 15px;
}

.logoImg {
  cursor: pointer;
  width: 180px;
  margin-top: 10px;
}

.menuLinks {
  text-align: center;
  width: 100%;
  padding: 20px 0 20px 5px;
}

.menuLinks > *, #login-link {
  cursor: pointer;
  color: var(--grey5);
  padding: 20px 15px;
  font-size: 28px;
  text-decoration: none;
}

.menuLinks :visited, #login-Link:visited {
  color: var(--grey5);
}

.menuLinks .active {
  color: var(--grey7);
}

.menuLinks :hover, #login-link:hover {
  cursor: pointer;
  color: var(--grey0);
  background-color: var(--grey6);
}

#login-link {
  padding: 26px 15px 20px;
}

.navContainer {
  z-index: 10;
  background-color: var(--grey4);
  width: 0;
  height: 100%;
  padding-top: 70px;
  transition: all .2s;
  position: fixed;
  left: 0;
  overflow-x: hidden;
}

.navbutton {
  cursor: pointer;
  color: var(--grey6);
  z-index: 11;
  width: 35px;
  padding: 18px 15px 15px 20px;
  display: none;
}

.navbutton > i.material-icons {
  font-size: 45px;
}

.navLink {
  color: var(--grey6);
  padding: 10px 15px 10px 20px;
  font-size: 28px;
  text-decoration: none;
  display: block;
}

.navLink:visited {
  color: var(--grey6);
}

.navLink:hover {
  background-color: var(--grey6);
  color: var(--grey0);
}

@media only screen and (width <= 1026px) {
  .menuLinks {
    display: none;
  }

  .navbutton {
    display: inline-flex;
  }

  .loginLink {
    position: absolute;
    top: 0;
    right: 0;
  }
}

@media only screen and (width <= 321px) {
  .logoImg {
    width: 150px;
  }

  .navbutton {
    width: 30px;
    height: 30px;
    padding-right: 5px;
  }
}

footer {
  background: var(--grey);
  color: var(--grey5);
  flex-shrink: 0;
  margin: 15px;
  font-size: 14px;
}

footer .text {
  display: inline;
}

footer .link {
  text-decoration: underline;
}

.timerange {
  opacity: .7;
  -webkit-transition: all .2s;
  width: 100%;
  transition: opacity .2s;
}

.timerange:hover {
  opacity: 1;
}

.timerange-value {
  color: #aaa;
  position: absolute;
  top: -1.5em;
}

.mithril-range {
  box-sizing: border-box;
  -webkit-user-select: none;
  user-select: none;
  cursor: pointer;
  -webkit-tap-highlight-color: #0000;
  width: 100%;
  padding: 1.5em 1em;
  position: relative;
}

.mithril-range-bar {
  box-sizing: border-box;
  width: 100%;
  height: .25em;
  position: relative;
}

.mithril-range-bar-0 {
  box-sizing: border-box;
  background-color: #ccc;
  width: 0%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}

.mithril-range-bar-1 {
  box-sizing: border-box;
  background-color: #888;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.mithril-range-handle {
  box-sizing: border-box;
  background-color: #fff;
  border: 1px solid #444;
  width: .75em;
  height: 1.5em;
  margin-top: -.625em;
  margin-left: -.375em;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
}

.json-tree div {
  text-align: left;
  margin-left: 20px;
}

h2, h3 {
  text-align: left;
}

.json-tree button {
  font-size: 50%;
}

/*# sourceMappingURL=index.e9dad5f3.css.map */

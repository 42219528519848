.json-tree div {
  margin-left: 20px;
  text-align: left;
}

h2, h3 {
  text-align: left;
}

.json-tree button {
  font-size: 50%;
}
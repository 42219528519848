.timerange {
  width: 100%;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.timerange:hover {
  opacity: 1;
}

.timerange-value {
  position: absolute;
  top: -1.5em;
  color: #aaa;
}
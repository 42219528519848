@import 'variables';
@import 'mixins';

$material-design-icons-font-directory-path: './fonts/' !default;
$material-design-icons-font-file-name: 'MaterialIcons-Regular' !default;
$material-design-icons-font-file-path: $material-design-icons-font-directory-path + $material-design-icons-font-file-name !default;
$material-design-icons-class-icons-generate-codepoints: true !default;
$material-design-icons-class-icons-prefix: '' !default;

@include main-font-face();
.material-icons {
  @include main-class();
}
